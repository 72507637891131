import React, {Component, Fragment} from "react";
import {NavLink} from "react-router-dom";
import {orderURL} from "config/api";
import NewOrders from "../OrderManagement/NewOrders/index";
import Completed from "../OrderManagement/Completed/index";
import UnderProcess from "./UnderProcess/index";
import CancelledOrder from "./Cancelled/index";
import Leads from "./Leads/index";
import {getOrderPermission, getPermission} from "helpers"
import Returns from "./Returns";
import {excelDownload} from "service/Common";
import {Toast} from "service/toast";
import {
    cancelledOrderFilter,
    completedOrderFilter,
    leadsFilter,
    newOrdersFilter,
    returnsFilter,
    underProcessFilter
} from "./utils";

import "./style.scss";
import {getUserHubs} from "../../service/Hub";

class OrderManagementComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
      tab: this.findGetParameter("tab"),

      /*
      * The below are states for 6 different filters.
      * Check it's respective component for its usage.
      * */

      newOrdersFilter: newOrdersFilter,
      underProcessFilter: underProcessFilter,
      completedOrderFilter: completedOrderFilter,
      cancelledOrderFilter: cancelledOrderFilter,
      leadsFilter: leadsFilter,
      returnsFilter: returnsFilter,

      hubNameData: []

    };

    /* To memoize the functions */
    this.filterHandler = this.filterHandler.bind(this);
    this.setFilterState = this.setFilterState.bind(this);

  }

  async componentDidMount() {
      await this.getAllHub();
  }

    /*
    * When the tab changes
    *
    * */
    async componentDidUpdate(prevProps, prevState){
        let tab = this.findGetParameter("tab")

        if(prevState.tab !== tab){
            if(tab === "newOrder") {
                this.setState({...this.state, tab, newOrdersFilter});

            }
            else if(tab === "underProcess") {
                this.setState({...this.state, tab, underProcessFilter});

            }
            else if(tab === "completed") {
                this.setState({...this.state, tab, completedOrderFilter});

            }
            else if(tab === "cancelled") {
                this.setState({...this.state, tab, cancelledOrderFilter});
            }
            else if(tab === 'leads') {
                this.setState({...this.state, tab, leadsFilter});
            }
            else if(tab === 'returns') {
                this.setState({...this.state, tab, returnsFilter});
            }
        }

    }

    getAllHub = async () => {
        try {
            const userId = localStorage.getItem('userId');
            let response = await getUserHubs(userId);

            if (response.status == 200) {
                this.setState({
                    hubNameData: response.data.data.map((item) => ({
                        name: `${item.name} (${item.code})`,
                        value: item._id,
                    })),
                    loading: false,
                });
            }
        } catch (error) {
            if(error.response){
                Toast({ type: "error", message: error.response.data.message });
            }
        }
    };


  findGetParameter(parameterName) {
    let result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  generateParamsForNewOrder = () => {
      let { startDate, endDate, paymentType, status, prescription, coldChain, imgUrls, orderTypeArr } = this.state.newOrdersFilter;
      let params = {
          startDate: startDate !== "" ? startDate : "",
          endDate: endDate !== "" ? endDate : "",
          orderType: orderTypeArr.map(x=>x.value).join(","),
          paymentType: paymentType.join(","),
          status: 0,
          prescription: prescription,
          coldChain: coldChain,
          imgUrls: imgUrls,
      }

      return params;
  }

  generateParamsForUnderProcess = () => {
      let { startDate, endDate, prescription, coldChain,  orderTypeArr, imgUrls, hubStatus, hubId } = this.state.underProcessFilter;
      let params = {
          startDate: startDate !== "" ? startDate : undefined,
          endDate: endDate !== "" ? endDate : undefined,
          prescription: prescription,
          coldChain: coldChain,
          imgUrls: imgUrls,
          orderType : orderTypeArr.map(x=>x.value).join(","),
          hubStatus: hubStatus.map(x=>x.value).join(","),
          hubId: hubId.map(x=>x.value).join(","),
          status: 1
      }

      return params;
  }

  generateParamsForCompletedOrders = () => {
      let { startDate, endDate, coldChain, imgUrls, orderTypeArr, shiprocketStatusArr, courierName, hubId, hubStatus } = this.state.completedOrderFilter;
      let params = {
          startDate: startDate !== "" ? startDate : undefined,
          endDate: endDate !== "" ? endDate : undefined,
          coldChain: coldChain,
          imgUrls: imgUrls,
          courierName: courierName,
          shiprocketStatus: shiprocketStatusArr.join(','),
          orderType: orderTypeArr && orderTypeArr.map(x=>x.value).join(","),
          hubStatus: hubStatus.map(x=>x.value).join(","),
          hubId: hubId.map(x=>x.value).join(","),
          status: 2,
      }
      return params;
  }

    generateParamsForCancelledOrders = () => {
        let { startDate, endDate, orderTypeArr } = this.state.cancelledOrderFilter;
        let params = {
            startDate: startDate !== "" ? startDate : undefined,
            endDate: endDate !== "" ? endDate : undefined,
            orderType: orderTypeArr.map(x=>x.value).join(","),
            status: 4
        }
        return params;
    }

    generateParamsForLeadOrders = () => {
        let { startDate, endDate, prescription, coldChain, imgUrls, orderTypeArr, status } = this.state.leadsFilter;
        let params = {
            startDate: startDate !== "" ? startDate : undefined,
            endDate: endDate !== "" ? endDate : undefined,
            prescription: prescription,
            coldChain: coldChain,
            imgUrls: imgUrls,
            orderType: orderTypeArr.map(x=>x.value).join(","),
            status: 5
        }
        return params;
    }

    generateParamsForReturnOrders = () => {
        let { startDate, endDate, coldChain, imgUrls, orderTypeArr, status, paymentType } = this.state.returnsFilter;
        let params = {
            startDate: startDate !== "" ? startDate : undefined,
            endDate: endDate !== "" ? endDate : undefined,
            coldChain: coldChain,
            imgUrls: imgUrls,
            paymentType: paymentType.join(","),
            orderType: orderTypeArr.map(x=>x.value).join(","),
            status: 8
        }
        return params;
    }

  downloadOrders = () => {
    if(!this.state.isDownload){
      this.setState(
        {
          isDownload: true,
        },
        async () => {

          let params = {}

          if(this.state.tab === "newOrder") {
            params = this.generateParamsForNewOrder();
          }
          else if(this.state.tab === "underProcess") {
            params = this.generateParamsForUnderProcess();
          }
          else if(this.state.tab === "completed") {
            params = this.generateParamsForCompletedOrders();
          }
          else if(this.state.tab === "cancelled") {
            params = this.generateParamsForCancelledOrders();
          }
          else if(this.state.tab === 'leads') {
            params = this.generateParamsForLeadOrders();
          }
          else if(this.state.tab === 'returns') {
              params = this.generateParamsForReturnOrders();
          }

          try {

          let url = `${orderURL}/order/download`;
            let response = await excelDownload(url, {...params, access: getOrderPermission("Download")}, true);
            if (response.status === 200) {

              Toast({ type: "success", message: response.data.message });
              this.setState({
                isDownload: false,
              });
            }
          } catch (error) {
            Toast({ type: "error", message: error.response.data.message });
            this.setState({
              isDownload: false,
            });
          }
        }
      );
    }
  };

  handleChange = (event) => {
    this.setState({
      dates: { ...this.state.dates, ...event },
    });
  };

  filterHandler = (e) => {
     if(this.state.tab === 'newOrder') {
       this.setState({...this.state, newOrdersFilter: {...this.state.newOrdersFilter, [e.target.name]: e.target.value }});
     }
     else if(this.state.tab === 'underProcess') {
          this.setState({...this.state, underProcessFilter: {...this.state.underProcessFilter, [e.target.name]: e.target.value }});
     }
     else if(this.state.tab === 'completed') {
          this.setState({...this.state, completedOrderFilter: {...this.state.completedOrderFilter, [e.target.name]: e.target.value }});
     }
     else if(this.state.tab === 'cancelled') {
         this.setState({...this.state, cancelledOrderFilter: {...this.state.cancelledOrderFilter, [e.target.name]: e.target.value }});
     }
     else if(this.state.tab === 'leads') {
         this.setState({...this.state, leadsFilter: {...this.state.leadsFilter, [e.target.name]: e.target.value }});
     }
     else if(this.state.tab === 'returns') {
         this.setState({...this.state, returnsFilter: {...this.state.returnsFilter, [e.target.name]: e.target.value }});
     }
  }

  setFilterState = (state) => {
      if(this.state.tab === 'newOrder') {
          this.setState({...this.state, newOrdersFilter: state});
      }
      else if(this.state.tab === 'underProcess') {
          this.setState({...this.state, underProcessFilter: state});
      }
      else if(this.state.tab === 'completed') {
          this.setState({...this.state, completedOrderFilter: state});
      }
      else if(this.state.tab === 'cancelled') {
          this.setState({...this.state, cancelledOrderFilter: state});
      }
      else if(this.state.tab === 'leads') {
          this.setState({...this.state, leadsFilter: state});
      }
      else if(this.state.tab === 'leads') {
          this.setState({...this.state, leadsFilter: state});
      }
      else if(this.state.tab === 'returns') {
          this.setState({...this.state, returnsFilter: state});
      }
  }


  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-6">
            <div className="order-management-title">
              <b>Order Management</b>
            </div>
          </div>
          <div className="col-md-6">
            <div className="order_btns">
              <NavLink id="navlink" to="/admin/ordermanagement/custom-message">
                <button className="custom-message">Custom Message</button>
              </NavLink>
              {getPermission("Order Management", "New Request", "Edit") && <NavLink id="navlink" to="/admin/ordermanagement/user?page=1&tab=neworder">
                <button className="create-order">Create Order</button>
              </NavLink>}
              {getOrderPermission("Download").split(",").length > 0 && <div className="download-btn">
                <button onClick={() => this.downloadOrders()}>
                  {this.state.isDownload ? (
                    <span style={{ color: "#766ceb" }}>Request Initiated...</span>
                  ) : (
                    <div>

                    <img
                    id="download"
                    alt='download'
                    src={require("assets/images/download.png")}
                   />
                   </div>                      
                  )}
                </button>
              </div>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="tabs">
              <ul>
                {getPermission("Order Management", "New Request") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=newOrder&amp;paymentType=&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "newOrder"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    New Request
                  </li>
                </NavLink>}
                {getPermission("Order Management", "Under Process") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=underProcess&amp;paymentType=&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "underProcess"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    Under Process
                  </li>
                </NavLink>}
                {getPermission("Order Management", "Completed") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=completed&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "completed"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    Completed
                  </li>
                </NavLink>}
                {getPermission("Order Management", "Cancelled") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=cancelled&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "cancelled"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    Cancelled
                  </li>
                </NavLink>}
                {getPermission("Order Management", "Leads") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=leads&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "leads"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    Leads
                  </li>
                </NavLink>}
                {getPermission("Order Management", "Returns") && <NavLink
                  id="navlink"
                  to="/admin/ordermanagement/list?tab=returns&amp;page=1&amp;size=10"
                >
                  <li
                    className={
                      this.state.tab === "returns"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                  >
                    Returns
                  </li>
                </NavLink>}
              </ul>
            </div>
          </div>
        </div>
        {this.state.tab === "newOrder" && (
          <NewOrders setFilterState={this.setFilterState} filterData={this.state.newOrdersFilter} onDateChange={this.handleChange}  onFilterChange={this.filterHandler} />
        )}
        {this.state.tab === "underProcess" && (
          <UnderProcess getAllHub={this.getAllHub} hubNameData={this.state.hubNameData} setFilterState={this.setFilterState} filterData={this.state.underProcessFilter} onFilterChange={this.filterHandler} onDateChange={this.handleChange} />
        )}
        {this.state.tab === "completed" && (
          <Completed hubNameData={this.state.hubNameData} setFilterState={this.setFilterState} filterData={this.state.completedOrderFilter} onFilterChange={this.filterHandler} onDateChange={this.handleChange} />
        )}
        {this.state.tab === "cancelled" && (
          <CancelledOrder setFilterState={this.setFilterState} filterData={this.state.cancelledOrderFilter} onFilterChange={this.filterHandler} onDateChange={this.handleChange} />
        )}
        {this.state.tab === "leads" && (
          <Leads setFilterState={this.setFilterState} filterData={this.state.leadsFilter} onFilterChange={this.filterHandler} onDateChange={this.handleChange} />
        )}
        {this.state.tab === "returns" && (
          <Returns setFilterState={this.setFilterState} filterData={this.state.returnsFilter} onFilterChange={this.filterHandler} onDateChange={this.handleChange} />
        )}
      </Fragment>
    );
  }
}

export default OrderManagementComponent
