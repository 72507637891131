import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup, Label, Button
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setOrdersList } from "../../../action/home";
import { history, isGeneralImage, dateDiff, getToken, getPermission } from "../../../helpers";
import { Toast } from "../../../service/toast";
import { io } from "socket.io-client";
import { getAllOrder, paymentDropDown, filterColumn } from "service/Order";
import jwt_decode from "jwt-decode";
import LimitFilter from "component/common/LimitFilter";
import { debounce } from "debounce";
import { newOrdersFilter } from "../utils";

import "../NewOrders/style.scss";
import Multiselect from 'multiselect-react-dropdown';
import { BsFilter } from "react-icons/bs";

class NewOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxPages: "",
      loading: true,
      viewAccess: getPermission("Order Management", "New Request", "View") || getPermission("Order Management", "New Request", "Edit"),
      isOpen: false,
      paymentFilter: [],
      paymentType: [],
      ordertypeoptions: [{ name: "Cart Flow", value: "1" }, { name: "Upload Rx", value: "0" }, { name: "Manual", value: "3" }],
    };

    this.reIntialFilter = this.reIntialFilter.bind(this);

  }
  async componentDidMount() {
    if (this.findGetParameter("paymentType") !== null) {
      this.setState({
        paymentType: this.findGetParameter("paymentType").split("|"),
      });
    }
    await this.getNewOrder();
    await this.getPaymentTypes();
    window.scrollTo(0, 0);
    window.addEventListener("popstate", this.getNewOrder);
    const socketOrder = io("http://doodlebluelive.com:2318");
    socketOrder.on("connect", () => {
      let token = getToken();
      let userInfo = jwt_decode(token);
      socketOrder.emit("adminUser", {
        permission: userInfo.userType === 1 ? [] : userInfo.permission,
      });
    });
    socketOrder.on("newOrder", (data) => {
      if (data.newOrder) {
        this.getNewOrder();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.getNewOrder);
  }
  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }
  getPaymentTypes = async () => {
    try {
      let response = await paymentDropDown();
      if (response.status == 200) {
        this.setState({
          paymentFilter: response.data.data,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };
  
  getNewOrder = async () => {
    const {orderTypeArr,paymentType,shiprocketStatusArr, ...filterData} = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      paymentType: paymentType.length ? paymentType.join() : undefined,
      page,
      size: this.findGetParameter("size"),
      status: 0,
      search: this.findGetParameter("search"),
    };
    if(orderTypeArr.length){
      params.orderType = orderTypeArr.map(type => type.value).join()
    }  
    try {
      const {size, status, search, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType} = params
      let response = await getAllOrder({size, status, search, page, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          params: {...params, orderTypeArr, paymentType, shiprocketStatusArr},
          prevPage: page,
          page: "newOrder"
        });
        this.setState({
          // newOrder: response.data.data.list,
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  handlePageClick = (event) => {
    if (this.findGetParameter("search") == null) {
      history.push(
        "/admin/ordermanagement/list?tab=newOrder&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("%7C")
      );
      // this.getNewOrder();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        // newOrder: [],
        loading: true,
      });
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=newOrder&search=" +
        this.findGetParameter("search") +
        "&paymentType=" +
        this.state.paymentType.join("	%7C") +
        "&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      this.getNewOrder();
      // this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        // newOrder: [],
        loading: true,
      });
    }
  };
  handleSearch = async (event) => {
    if (event.target.value === "") {
      await history.push(
        "/admin/ordermanagement/list?tab=newOrder&page=1&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("	%7C")
      );
      this.setState(
        {
          // newOrder: [],
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getNewOrder();
          }, 1000);
        }
      );
    } else {
      await history.push(
        "/admin/ordermanagement/list?tab=newOrder&search=" +
        String(event.target.value).replace("#", "").trim() +
        "&paymentType=" +
        this.state.paymentType.join("	%7C") +
        "&page=1&size=" +
        this.findGetParameter("size")
      );
      this.setState(
        {
          // newOrder: [],
          maxPages: 1,
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getNewOrder();
          }, 1000);
        }
      );
    }
  };
  paymentFilter = (event) => {
    if (event.target.checked) {
      this.state.paymentType.push(event.target.value);
      this.setState(
        {
          paymentType: this.state.paymentType,
          loading: true,
        },
        async () => {
          if (this.findGetParameter("search") == null) {
            await history.push(
              "/admin/ordermanagement/list?tab=newOrder&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getNewOrder();
          } else {
            await history.push(
              "/admin/ordermanagement/list?tab=newOrder&search=" +
              this.findGetParameter("search") +
              "&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getNewOrder();
          }
        }
      );
    } else {
      let index = this.state.paymentType.findIndex((value) => {
        return value == event.target.value;
      });
      this.state.paymentType.splice(index, 1);
      this.setState(
        {
          paymentType: this.state.paymentType,
          loading: true,
        },
        async () => {
          if (this.findGetParameter("search") == null) {
            await history.push(
              "/admin/ordermanagement/list?tab=newOrder&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getNewOrder();
          } else {
            await history.push(
              "/admin/ordermanagement/list?tab=newOrder&search=" +
              this.findGetParameter("search") +
              "&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getNewOrder();
          }
        }
      );
    }
  };
  handleDateFilter = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          // this.getNewOrder();
          this.props.onDateChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        }
      }
    );
  };
  handleLimit = async (event) => {
    if (this.findGetParameter("search") != null) {
      history.push(
        "/admin/ordermanagement/list?tab=newOrder&page=1&paymentType=" +
        "&size=" +
        event +
        "&search=" +
        this.findGetParameter("search")
      );
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=newOrder&page=1&paymentType=" +
        "&size=" +
        event
      );
    }
    await this.getNewOrder();
    await this.filterTypedata();
  };
  //Modal open Function
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  reIntialFilter(){
    this.toggle();
    //this.props.setFilterState(newOrdersFilter); // Re-initialize to empty state
  }
  handleFilterChange = (e) => {
    this.props.onFilterChange(e);
  }

  //Filter column function
  filterTypedata = async () => {
    const {orderTypeArr,paymentType, shiprocketStatusArr, ...filterData} = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      paymentType: paymentType.length ? paymentType.join() : undefined,
      page,
      size: this.findGetParameter("size"),
      status: 0,
    };
    console.log('FILTER', params);
    if(orderTypeArr.length){
      params.orderType = orderTypeArr.map(type => type.value).join()
    }  
    try {
      const {size, status, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType} = params
      let response = await filterColumn({size, status, page, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
            ...response.data.data,
            params: {...params, orderTypeArr, paymentType, shiprocketStatusArr},
            prevPage: page,
            page: "newOrder"
        });
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
          modal: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  }

  render() {
    return (
      <div id="animated" className="fade-in">
        <div className="row">
          <div className="col-md-6">
            <input
              className="search-field"
              onChange={debounce(this.handleSearch, 500)}
              type="text"
              defaultValue={
                this.findGetParameter("search") != null
                  ? this.findGetParameter("search")
                  : null
              }
              placeholder="Search"
            />
            <div className="search-icon">
              <img src={require("../../../assets/images/search.png")} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="order-filter">
              <div class="filter-column">
                <button type="button" className="filterbtn" onClick={this.toggle}><BsFilter />&nbsp;&nbsp;Filter</button>
                <Modal isOpen={this.state.modal} toggle={()=>this.reIntialFilter()} className={this.props.className}>
                  <ModalHeader toggle={()=>this.reIntialFilter()}>Filter</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <span>StartDate</span>
                      <input
                        type="date"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.startDate}
                        onChange={this.handleFilterChange}
                      />
                      <span>End Date</span>
                      <input
                        type="date"
                        name="endDate"
                        min={this.props.filterData.startDate}
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.endDate}
                        onChange={this.handleFilterChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Prescription</Label>
                      <Input
                        type="select"
                        name="prescription"
                        id="exampleSelect"
                        value={this.props.filterData.prescription}
                        onChange={this.handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Cold Chain</Label>
                      <Input
                        type="select"
                        name="coldChain"
                        id="exampleSelect1"
                        value={this.props.filterData.coldChain}
                        onChange={this.handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Photos</Label>
                      <Input
                        type="select"
                        name="imgUrls"
                        id="exampleSelect2"
                        value={this.props.filterData.imgUrls}
                        onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="General+New">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Payment Type</Label>
                      <Multiselect
                        isObject={false}
                        options={this.state.paymentFilter.map(data => data._id)}
                        selectedValues={this.props.filterData.paymentType}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "paymentType" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "paymentType" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">OrderType</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.ordertypeoptions}
                        selectedValues={this.props.filterData.orderTypeArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="cancelfilterbtn" onClick={this.reIntialFilter}>Clear Filter</Button>
                    <Button className="applyfilterbtn" onClick={() => this.handlePageClick({ selected: 0 }) && this.filterTypedata()}>Apply Filter</Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="page-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="list-box">
                <table className="new-order-table">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>Order Date <br/>
                        Order Time
                      </th>
                      <th>Duration</th>
                      <th>Rx</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>
                        <UncontrolledButtonDropdown>
                          <DropdownToggle tag="span" data-toggle="dropdown">
                            <span style={{ cursor: "pointer" }}>
                              Payment Type
                            </span>
                          </DropdownToggle>
                          <DropdownMenu bottom>
                            <div className="payment-filter">
                              {this.state.paymentFilter.map((list, index) => {
                                return (
                                  <div key={index}>
                                    <input
                                      type="checkbox"
                                      name="payment"
                                      className="custom-checkbox"
                                      value={list._id}
                                      checked={this.state.paymentType.includes(
                                        list._id
                                      )}
                                      onChange={this.paymentFilter}
                                    />
                                    <label>{list._id}</label>
                                  </div>
                                );
                              })}
                            </div>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </th>
                      <th>Order Type</th>
                      <th>Amount</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ordersList.list.length > 0 ? (
                      this.props.ordersList.list.map((value, index) => {
                        return (
                          <tr key={index} className="table_data">
                            <td>
                              {this.findGetParameter("page") == 1
                                ? index + 1
                                : Number(
                                  (this.findGetParameter("page") - 1) *
                                  this.findGetParameter("size")
                                ) + Number(index + 1)}
                            </td>
                            <td>
                              {this.state.viewAccess ? <NavLink
                                id="navlink"
                                to={
                                  value.items.length === 0
                                    ? `/admin/ordermanagement/add?id=${value._id}&tab=newOrder`
                                    : `/admin/orderManagement/details?id=${value._id}&tab=newOrder`
                                }
                              >
                                <span className="view-request">
                                  {value.orderId}
                                </span>
                              </NavLink> : value.orderId}
                            </td>
                            <td>
                              {value.addressName}
                              <br />
                              {value.user && (
                                <>{(getPermission("User Data", "View") || getPermission("User Data", "Edit")) ? <NavLink
                                  id="navlink"
                                  to={`/admin/userdata/details?id=${value.userId}`}
                                >
                                  <span className="view-request">
                                    {value.user.name}
                                  </span>
                                </NavLink> : value.user.name}
                              </>)}{" "}
                            </td>
                            <td>
                              <span
                                className={
                                  value.isExistingUser ? "green-dot" : "red-dot"
                                }
                              ></span>
                            </td>
                            <td>
                              {String(value.orderStartDate).split(",")[0]} <br/>
                              {String(value.orderStartDate).split(",")[1]}
                            </td>
                            <td>
                              <span>{dateDiff(value.orderStartDate)}</span>
                            </td>
                            <td>
                              {value.prescriptionUrls.length !== 0 ? (
                                <>{this.state.viewAccess ? 
                                  <NavLink
                                  id="navlink"
                                  to={
                                    value.items.length === 0
                                      ? `/admin/ordermanagement/add?id=${
                                      value._id}&tab=newOrder`
                                      : `/admin/ordermanagement/details?id=${
                                      value._id}&tab=newOrder`
                                  }
                                >
                                  <span className="view-request">View</span>
                                </NavLink> : <span>View</span>}
                                </>
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                            <td>
                              {value.items.some((list) => {
                                return list.coldChain == "Yes";
                              }) ? (
                                <img
                                  src={require("assets/images/cold-chain.svg")}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{isGeneralImage(value)}</td>
                            <td>
                              {value.paymentType != null &&
                                value.paymentStatus == 2 &&
                                value.paymentType != "COD" ? (
                                <span>{value.paymentType}</span>
                              ) : value.paymentType == "COD" ? (
                                <span>COD</span>
                              ) : (
                                <span>--</span>
                              )}

                            </td>
                            <td>
                              {value.orderType == 1
                                ? <span>Cart Flow</span>
                                : value.orderType == 3
                                  ? <span>Manual</span>
                                  : <span>Upload Rx</span>}
                            </td>
                            <td>
                              {value.paymentType == "COD" && value.paymentStatus == 0 ?
                                (<span className="unpaid-order">
                                  Rs. {Number(value.totalPrice).toFixed(2)}
                                </span>) :
                                value.paymentStatus == 0 ? (
                                  <span className="unpaid-order">
                                    Rs. {Number(value.totalPrice).toFixed(2)}
                                  </span>
                                ) : ""}

                              {value.paymentType == "COD" && value.paymentStatus == 2 ? (
                                <span className="paid-order">
                                  Rs. {Number(value.totalPrice).toFixed(2)}
                                </span>
                              ) : value.paymentStatus == 2 ? (
                                <span className="paid-order">
                                  Rs. {Number(value.totalPrice).toFixed(2)}
                                </span>
                              ) : ""}

                              {value.paymentStatus == 3 && (
                                <span>Refund Initiated</span>
                              )}
                              {value.paymentStatus == 4 && (
                                <span>Refund Processed</span>
                              )}
                              {value.paymentStatus == 5 && (
                                <span>Refund Failed</span>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table_data">
                        <td colSpan="13">
                          {this.state.search != "" ? (
                            <span id="no-record">No Record Found</span>
                          ) : (
                            <span id="no-record">New order is empty</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.maxPages > 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <LimitFilter
                      value={this.findGetParameter("size")}
                      onChange={this.handleLimit}
                    />
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"previous-page"}
                      previousLinkClassName={"previous-link"}
                      nextLabel={"Next"}
                      nextClassName={"previous-page"}
                      nextLinkClassName={"previous-link"}
                      breakLabel={"..."}
                      breakClassName={"break"}
                      forcePage={parseInt(this.findGetParameter("page")) - 1}
                      breakLinkClassName={"break-link"}
                      pageCount={this.state.maxPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"inactive-page"}
                      pageLinkClassName={"inactive-link"}
                      activeLinkClassName={"active-link"}
                      activeClassName={"active-page"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span id="total-pages">
                      Total Pages : {this.state.maxPages}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}

const mapDispatchToProps = dispatch => ({
  setOrdersList: ordersList => dispatch(setOrdersList(ordersList)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewOrders);
