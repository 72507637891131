import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup, Label, Button
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { Toast } from "service/toast";
import { dateDiff, history, getPaymentStatus, getPermission } from "helpers";
import { debounce } from "debounce";
import { getAllOrder, updateOrder, initiateRefund, filterColumn } from "service/Order";
import LimitFilter from "component/common/LimitFilter";
import { connect } from "react-redux";
import { setOrdersList } from "action/home";

import "../Cancelled/style.scss";
import Multiselect from 'multiselect-react-dropdown';
import { BsFilter } from "react-icons/bs";

class CancelledOrder extends Component {
  constructor(props) {
    super(props);
    this.initialFilterData = {
      startDate: "", 
      endDate: "", 
      orderType: "",
      orderTypeArr: []
    }
    this.state = {
      editPermission: getPermission("Order Management", "Cancelled", "Edit"),
      maxPages: "",
      searchMaxPages: "",
      openModal: false,
      loading: true,
      ordertypeoptions: [{ name: "Cart Flow", value: "1" }, { name: "Upload Rx", value: "0" }, { name: "Manual", value: "3" }],
      filterData: {
        startDate: this.props.ordersList.params.startDate || "", 
        endDate: this.props.ordersList.params.endDate || "", 
        orderType: this.props.ordersList.params.orderType || "",
        orderTypeArr: this.props.ordersList.params.orderTypeArr || [],
      }
    };
  }
  componentDidMount() {
    this.getCancelled();
    window.scrollTo(0, 0);
    window.addEventListener("popstate", this.getCancelled);
  }
  componentWillUnmount() {
    window.removeEventListener("popstate", this.getCancelled);
  }

  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }
  
  getCancelled = async () => {
    const { orderTypeArr, shiprocketStatusArr,  ...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      page,
      size: this.findGetParameter("size"),
      status: 4,
      search: this.findGetParameter("search")
        ? this.findGetParameter("search")
        : null,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    try {
      const { size, search, status, startDate, endDate, orderType} = params
      let response = await getAllOrder({page, size, search, status, startDate, endDate, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          page: "cancelled",
          prevPage: page,
          params: { ...params, orderTypeArr, shiprocketStatusArr }
        })
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  handlePageClick = (event) => {
    if (this.findGetParameter("search") == null) {
      history.push(
        "/admin/ordermanagement/list?tab=cancelled&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      // this.getCancelled();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        loading: true,
        // cancelled: [],
      });
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=cancelled&search=" +
        this.findGetParameter("search") +
        "&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      this.getCancelled();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        loading: true,
        // cancelled: [],
      });
    }
  };
  handleSearch = async (event) => {
    if (event.target.value === "") {
      await history.push(
        "/admin/ordermanagement/list?tab=cancelled&page=1&size=" +
        this.findGetParameter("size")
      );
      this.setState(
        {
          search: "",
          loading: true,
          // cancelled: [],
        },
        () => {
          setTimeout(() => {
            this.getCancelled();
          }, 1000);
        }
      );
    } else {
      await history.push(
        `/admin/ordermanagement/list?tab=cancelled&search=${String(
          event.target.value
        )
          .replace("#", "")
          .trim()}&page=1&size=${this.findGetParameter("size")}`
      );
      this.setState(
        {
          loading: true,
          maxPages: 1,
          // cancelled: [],
        },
        () => {
          setTimeout(() => {
            this.getCancelled();
          }, 1000);
        }
      );
    }
  };

  refundInitiated = async (id, userId, paymentId, amount, gateWay) => {
    if (gateWay == 2 && paymentId) {
      let body = {
        amount: Number(amount).toFixed(2).toString(),
        paymentId,
      };
      try {
        let response = await initiateRefund(body);
        if (response.status == 200) {
          let data = {
            _id: id,
            userId: userId,
            paymentStatus: "4",
          };
          this.updateRefundStatus(data);
        }
      } catch (error) {
        Toast({
          type: "error",
          message: error.response.data.message,
        });
      }
    } else {
      let data = {
        _id: id,
        userId: userId,
        paymentStatus: "4",
      };
      this.updateRefundStatus(data);
    }
  };

  refundProcessed(id, userId) {
    let data = {
      _id: id,
      userId: userId,
      paymentStatus: "5",
    };
    this.updateRefundStatus(data);
  }

  updateRefundStatus = async (data) => {
    try {
      let response = await updateOrder(data);
      if (response.status == 200) {
        this.getCancelled();
        Toast({
          type: "success",
          message: "Payment status has been updated successfully",
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  handleDateFilter = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          // this.getCancelled();
          this.props.onDateChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        }
      }
    );
  };

  handleLimit = (event) => {
    if (this.findGetParameter("search") != null) {
      history.push(
        "/admin/ordermanagement/list?tab=cancelled&page=1" +
        "&size=" +
        event +
        "&search=" +
        this.findGetParameter("search")
      );
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=cancelled&page=1" + "&size=" + event
      );
    }
    this.getCancelled();
  };

  //Modal open Function
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  reIntialFilter(){
    this.toggle()
    this.setState({filterData: {
        startDate: this.props.ordersList.params.startDate || "", 
        endDate: this.props.ordersList.params.endDate || "", 
        orderType: this.props.ordersList.params.orderType || "",
        orderTypeArr: this.props.ordersList.params.orderTypeArr || [],
    }})
  }
  handleFilterChange = (e) => {

    this.props.onFilterChange(e);

   /* this.setState({
      filterData: {
        ...this.state.filterData,
        [e .target.name]: e.target.value
      }
    })*/
  }
  //Filter column function
  filterTypedata = async () => {
    const { orderTypeArr, shiprocketStatusArr,  ...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      page,
      size: this.findGetParameter("size"),
      status: 4,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    try {
      const { size, status, startDate, endDate, orderType} = params
      let response = await filterColumn({page, size, status,startDate, endDate, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          page: "cancelled",
          prevPage: page,
          params: { ...params, orderTypeArr, shiprocketStatusArr}
        })
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
          modal: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  }

  render() {
    return (
      <div id="animated" className="fade-in">
        <div className="row">
          <div className="col-md-6">
            <input
              className="search-field"
              onChange={debounce(this.handleSearch, 500)}
              type="text"
              placeholder="Search"
              defaultValue={
                this.findGetParameter("search") != null
                  ? this.findGetParameter("search")
                  : null
              }
            />
            <div className="search-icon">
              <img src={require("../../../assets/images/search.png")} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="order-filter">
              <div class="filter-column">
                <button type="button" className="filterbtn" onClick={this.toggle}><BsFilter />&nbsp;&nbsp;Filter</button>
                <Modal isOpen={this.state.modal} toggle={()=>this.reIntialFilter()} className={this.props.className}>
                  <ModalHeader toggle={()=>this.reIntialFilter()}>Filter</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <span>StartDate</span>
                      <input
                        type="date"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.startDate}
                        onChange={this.handleFilterChange}
                      />
                      <span>End Date</span>
                      <input
                        type="date"
                        name="endDate"
                        min={this.props.filterData.startDate}
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.endDate}
                        onChange={this.handleFilterChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">OrderType</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.ordertypeoptions}
                        selectedValues={this.props.filterData.orderTypeArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="cancelfilterbtn" onClick={() => this.props.setFilterState(this.initialFilterData)}>Clear Filter</Button>
                    <Button className="applyfilterbtn" onClick={() => this.handlePageClick({ selected: 0 }) && this.filterTypedata()}>Apply Filter</Button>{' '}
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="page-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="list-box">
                <table className="cancel-order-table w-100">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>Cancel Reason</th>
                      <th>Cancelled On</th>
                      <th>Duration</th>
                      <th>Payment Status</th>
                      <th>OrderType</th>
                      {this.state.editPermission && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ordersList.list.length > 0 ? (
                      this.props.ordersList.list.map((value, index) => {
                        return (
                          <tr key={index} className="table_data">
                            <td>
                              {this.findGetParameter("page") == 1
                                ? index + 1
                                : Number(
                                  (this.findGetParameter("page") - 1) *
                                  this.findGetParameter("size")
                                ) + Number(index + 1)}
                            </td>
                            <td>
                              {(getPermission("Order Management", "Cancelled", "View") || getPermission("Order Management", "Cancelled", "Edit")) ? <NavLink
                                id="navlink"
                                to={
                                  value.items.length === 0
                                    ? `/admin/ordermanagement/add?id=${value._id}&tab=cancelled`
                                    : `/admin/orderManagement/details?id=${value._id}&tab=cancelled`
                                }
                              >
                                <span className="view-request">
                                  {value.orderId}
                                </span>
                              </NavLink>: value.orderId}
                            </td>
                            <td>
                              {value.addressName}
                              <br />
                              {value.user && (
                                <>{(getPermission("User Data", "View") || getPermission("User Data", "Edit")) ? <NavLink
                                  id="navlink"
                                  to={`/admin/userdata/details?id=${value.userId}`}
                                >
                                  <span className="view-request">
                                    {value.user.name}
                                  </span>
                                </NavLink> : value.user.name}
                              </>)}{" "}
                            </td>

                            <td>
                              <span
                                className={
                                  value.isExistingUser ? "green-dot" : "red-dot"
                                }
                              ></span>
                            </td>
                            <td>{value.cancelReason}</td>
                            <td>{String(value.orderEndDate).split(",")[0]}</td>
                            <td>
                              <span>
                                {dateDiff(
                                  value.orderStartDate,
                                  value.orderEndDate
                                )}
                              </span>
                            </td>
                            <td>
                              <span>
                                {getPaymentStatus(value.paymentStatus)}
                              </span>
                            </td>
                            <td>
                              {value.orderType == 1
                                ? <span>Cart Flow</span>
                                : value.orderType == 3
                                  ? <span>Manual</span>
                                  : <span>Upload Rx</span>}
                            </td>
                            {this.state.editPermission && <td className="dropdown">
                              {(value.paymentStatus == 3 ||
                                value.paymentStatus == 4) && (
                                  <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                      tag="span"
                                      data-toggle="dropdown"
                                    >
                                      <i className="icon-dot dot"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      {value.paymentStatus == 3 && (
                                        <DropdownItem
                                          onClick={() =>
                                            this.refundInitiated(
                                              value._id,
                                              value.userId,
                                              value.paymentId,
                                              value.totalPrice,
                                              value.paymentGateway
                                            )
                                          }
                                        >
                                          <div>
                                            <img
                                              className="refund-icon"
                                              src={require("../../../assets/images/refund.svg")}
                                            />
                                            <span>Refund Initiated</span>
                                          </div>
                                        </DropdownItem>
                                      )}
                                      {(value.paymentStatus == 3 ||
                                        value.paymentStatus == 4) && (
                                          <DropdownItem
                                            onClick={() =>
                                              this.refundProcessed(
                                                value._id,
                                                value.userId
                                              )
                                            }
                                          >
                                            <div>
                                              <img
                                                className="refund-icon"
                                                src={require("assets/images/dollar.svg")}
                                              />
                                              <span>Refund Completed</span>
                                            </div>
                                          </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                )}
                            </td>}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table_data">
                        <td colSpan="9">
                          {this.state.search != "" ? (
                            <span id="no-record">No Record Found</span>
                          ) : (
                            <span id="no-record">Cancel is empty</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.maxPages > 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <LimitFilter
                      value={this.findGetParameter("size")}
                      onChange={this.handleLimit}
                    />
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"previous-page"}
                      previousLinkClassName={"previous-link"}
                      nextLabel={"Next"}
                      nextClassName={"previous-page"}
                      nextLinkClassName={"previous-link"}
                      breakLabel={"..."}
                      breakClassName={"break"}
                      breakLinkClassName={"break-link"}
                      forcePage={parseInt(this.findGetParameter("page")) - 1}
                      pageCount={this.state.maxPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"inactive-page"}
                      pageLinkClassName={"inactive-link"}
                      activeLinkClassName={"active-link"}
                      activeClassName={"active-page"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span id="total-pages">
                      Total Pages : {this.state.maxPages}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}

const mapDispatchToProps = dispatch => ({
  setOrdersList: ordersList => dispatch(setOrdersList(ordersList)),
})



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelledOrder)
