import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
    DropdownToggle,
  DropdownMenu,
    Modal,
  ModalHeader,
    ModalBody,
    ModalFooter,
  Input,
  FormGroup, Label, Button
} from "reactstrap";
// import { Modal, ModalBody } from "reactstrap";
import ReactPaginate from "react-paginate";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import {Toast} from "../../../service/toast";
import { dateDiff, history, isGeneralImage, getPermission } from "../../../helpers";
import {debounce} from "debounce";
import {
  getAllOrder,
  paymentDropDown,
  updateOrder,
  pickerrTracking,
  filterColumn
} from "service/Order";
import LimitFilter from "component/common/LimitFilter";
import { setOrdersList, clearFilterHandler } from "../../../action/home";
import {getGlobalVariables} from "service/Common";
import { getAllHubs, getUserHubs } from "service/Hub";
import dateFormat from "dateformat";

import "../UnderProcess/style.scss";
import Multiselect from 'multiselect-react-dropdown';
import {BsFilter} from "react-icons/bs";

class UnderProcess extends Component {
  constructor(props) {
    super(props);
    this.initialFilterData = {
      startDate: "", 
      endDate: "", 
      prescription: "",
      coldChain: "",
      imgUrls: "", 
      paymentType: [],
      orderType: "",
      orderTypeArr: [],
      hubStatus: [],
      hubId: [],
      selectedHubs:[],
      selectedHubStatus:[]
    }
    this.state = {
      // underProcess: [],
      completedDetails: {
        _id: "",
        deliveredOn: "",
        deliveredBy: "",
        userId: "",
        adminStatus: 2,
      },
      maxPages: "",
      searchMaxPages: "",
      openModal: false,
      search: "",
      loading: true,
      paymentFilter: [],
      paymentType: [],
      paymentModal: false,
      orderData: {},
      globalVariable: {},
      ordertypeoptions: [{ name: "Cart Flow", value: "1" }, { name: "Upload Rx", value: "0" }, { name: "Manual", value: "3" }],
      hubNameData: [],
      nullHubs: 1,
      hubStatusOptions: [{ name: "Unaccepted", value: "0"}, { name: "Accepted", value: "1"}, { name: "Rejected", value: "2"}],
      selectedHubs:this.props.ordersList.params.selectedHubs || [],
      selectedHubStatus:this.props.ordersList.params.selectedHubStatus || [],
    };
  }
  async componentDidMount() {
    if (this.findGetParameter("paymentType") !== null) {
      this.setState({
        paymentType: this.findGetParameter("paymentType").split("|"),
      });
    }



    await this.props.getAllHub();

    this.props.setFilterState({
      ...this.props.filterData,
      //hubId: this.props.filterData.hubId.length > 0 ? this.props.ordersList.params.selectedHubs : this.props.ordersList.params.selectedHubs || this.props.hubNameData,
      hubId: this.props.hubNameData,
      //hubStatus:  this.props.filterData.hubStatus.length > 0 ? this.props.ordersList.params.selectedHubStatus : this.props.ordersList.params.selectedHubStatus || this.props.ordersList.params.hubStatus || [],
      hubStatus:  this.props.filterData.hubStatus
    });

    await this.getUnderProcess();
    await this.getPaymentTypes();
    await this.getGlobalVariable();


    window.scrollTo(0, 0);
    window.addEventListener("popstate", this.getUnderProcess);
  }
  componentWillUnmount() {
    window.removeEventListener("popstate", this.getUnderProcess);
  }
  findGetParameter(parameterName) {
    let result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  getAllHub = async () => {
    try {
      const userId = localStorage.getItem('userId');
      let response = await getUserHubs(userId);

      if (response.status == 200) {
        this.setState({
          hubNameData: response.data.data.map((item) => ({
            name: `${item.name} (${item.code})`,
            value: item._id,
          })),
          //loading: false,
        });
      }
    } catch (error) {
      if(error.response) {
        Toast({ type: "error", message: error.response.data.message });
      }
    }
  };

  getGlobalVariable = async () => {
    try {
      let response = await getGlobalVariables();
      if (response.status == 200) {
        this.setState({
          globalVariable: response.data.data,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  getPaymentTypes = async () => {
    try {
      let response = await paymentDropDown();
      if (response.status == 200) {
        this.setState({
          paymentFilter: response.data.data,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  getUnderProcess = async () => {
    if(this.props.hubNameData.length == 0){

      this.setState({
        nullHubs:0,
        loading: false,
          modal: false,
      });

    }
    else{
      this.setState({
        nullHubs:1,
        loading: true,
        modal: false,
      });
    const { orderTypeArr, paymentType,shiprocketStatusArr,hubId, hubStatus,...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      paymentType: paymentType.length ? paymentType.join() : undefined,
      page,
      size: this.findGetParameter("size"),
      status: 1,
      search: this.findGetParameter("search")
        ? this.findGetParameter("search")
        : null,
    };
    if (orderTypeArr.length) {
      params.orderType = orderTypeArr.map((type) => type.value).join();
    }
    if (hubId && hubId.length) {
      params.hubId = hubId.map((type) => type.value).join();
      params.selectedHubs = hubId
      this.setState({
        ...this.state,
        selectedHubs:hubId
      })
    }
    if (hubStatus.length>0) {
      params.hubStatus = hubStatus.map((type) => type.value).join();
      params.selectedHubStatus = hubStatus
      this.props.setFilterState({...this.props.filterData, hubId});
    }
    try {

      const {size, status, search, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType, hubStatus} = params;
      let response = await getAllOrder({
        size, status, search, page, startDate, endDate, prescription, coldChain, imgUrls,
        paymentType: paymentTypeData,
        orderType,
        hubId:this.props.filterData.hubId.map((item)=>{
          return item.value
         }).join(),
         hubStatus
      });
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          page: "underProcess",
          prevPage: page,
          params: { ...params, orderTypeArr, paymentType, shiprocketStatusArr },
        });
        this.setState({

          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
        });
      }
    } catch (error) {
      //Toast({ type: "error", message: error.response.data.message });
    }
  }};

  handleChange = (event) => {
    let formData = {
      ...this.state.completedDetails,
      [event.target.name]: event.target.value,
    };
    this.setState({
      completedDetails: formData,
    });
  };

  getUpperCase = (word) => {
    const mySentence = word.toLowerCase();

    const finalSentence = mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    return finalSentence;
}

  handleUpdate = async () => {
    try {
      let response = await updateOrder(this.state.completedDetails);
      if (response.status == 200) {
        Toast({ type: "success", message: "Mark as completed" });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  handlePageClick = async (event) => {
    if (this.findGetParameter("search") == null) {
      history.push(
        "/admin/ordermanagement/list?tab=underProcess&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("%7C")
      );
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({

        loading: true,
      });
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=underProcess&search=" +
        this.findGetParameter("search") +
        "&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("%7C")
      );
      this.getUnderProcess();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({

        loading: true,
      });
    }
  };
  handleSearch = async (event) => {
    if (event.target.value == "") {
      await history.push(
        "/admin/ordermanagement/list?tab=underProcess&page=1&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("%7C")
      );
      this.setState(
        {
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getUnderProcess();
          }, 1000);
        }
      );
    } else {
      await history.push(
        "/admin/ordermanagement/list?tab=underProcess&search=" +
        String(event.target.value).replace("#", "").trim() +
        "&page=1&size=" +
        this.findGetParameter("size") +
        "&paymentType=" +
        this.state.paymentType.join("%7C")
      );
      this.setState(
        {
          maxPages: 1,
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getUnderProcess();
          }, 1000);
        }
      );
    }
  };

  paymentToggle = () => {
    this.setState({
      paymentModal: !this.state.paymentModal,
    });
  };

  changePaymentStatus = async () => {
    let coldChain = this.state.orderData.items.every((list) => {
      return list.coldChain !== "Yes";
    });
    let codFee =
      (this.state.orderData.totalPrice *
        this.state.globalVariable.orderConvenienceFee) /
      100;
    let total = this.state.orderData.totalPrice + codFee;
    let data = {
      _id: this.state.orderData.id,
      adminStatus: 2,
      orderConfirmedDate: dateFormat(new Date(), "dS mmm yyyy, h:MM tt"),
      paymentType: "COD",
      codPercentage: this.state.globalVariable.orderConvenienceFee,
      codCharge: codFee.toFixed(2),
      totalPrice: total.toFixed(2),
      userId: this.state.orderData.userId,
    };
    if (total <= this.state.globalVariable.maximumCodAmount && coldChain) {
      try {
        let response = await updateOrder(data);
        if (response.status == 200) {
          Toast({ type: "success", message: "Order Marked as COD" });
          this.getUnderProcess();
          this.paymentToggle();
        }
      } catch (error) {
        Toast({ type: "error", message: error.response.data.message });
      }
    } else {
      if (!coldChain) {
        Toast({
          type: "error",
          message: "COD is not applicable for Cold Chain Products",
        });
      } else {
        Toast({
          type: "error",
          message: `COD is not available for order above Rs.${Number(
            this.state.globalVariable.maximumCodAmount
          ).toLocaleString("en-In", {
            maximumFractionDigits: 2,
            currency: "INR",
          })}`,
        });
      }
    }
  };

  openModal(id, userId) {
    let data = { ...this.state.completedDetails, _id: id, userId: userId };
    this.setState({
      completedDetails: data,
      openModal: !this.state.openModal,
    });
  }

  paymentFilter = (event) => {
    if (event.target.checked) {
      this.state.paymentType.push(event.target.value);
      this.setState(
        {
          paymentType: this.state.paymentType,
          loading: true,
        },
        async () => {
          if (this.findGetParameter("search") == null) {
            await history.push(
              "/admin/ordermanagement/list?tab=underProcess&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getUnderProcess();
          } else {
            await history.push(
              "/admin/ordermanagement/list?tab=underProcess&search=" +
              this.findGetParameter("search") +
              "&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getUnderProcess();
          }
        }
      );
    } else {
      let index = this.state.paymentType.findIndex((value) => {
        return value == event.target.value;
      });
      this.state.paymentType.splice(index, 1);
      this.setState(
        {
          paymentType: this.state.paymentType,
          loading: true,
        },
        async () => {
          if (this.findGetParameter("search") == null) {
            await history.push(
              "/admin/ordermanagement/list?tab=underProcess&page=1&size=" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getUnderProcess();
          } else {
            await history.push(
              "/admin/ordermanagement/list?tab=underProcess&search=" +
              this.findGetParameter("search") +
              "&page=1" +
              this.findGetParameter("size") +
              "&paymentType=" +
              this.state.paymentType.join("	%7C")
            );
            await this.getUnderProcess();
          }
        }
      );
    }
  };

  handleDateFilter = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          this.props.onDateChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        }
      }
    );
  };

  handleLimit = (event) => {
    if (this.findGetParameter("search") != null) {
      history.push(
        "/admin/ordermanagement/list?tab=underProcess&page=1" +
        "&size=" +
        event +
        "&search=" +
        this.findGetParameter("search") +
        "&paymentType="
      );
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=underProcess&page=1" +
        "&size=" +
        event +
        "&paymentType="
      );
    }
    this.getUnderProcess();
  };
  reIntialFilter(){
    this.toggle();
  }
  //Modal open Function
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  handleFilterChange = (e) => {
    this.props.onFilterChange(e);
      }

  //Filter column function
  filterTypedata = async () => {
    if (this.props.filterData.hubId.length === 0){
      this.setState({
        nullHubs:0,
        loading: false,
          modal: false,
      });
    }
      else{
        await this.getAllHub();
        this.setState({
          nullHubs:1,
          modal: false,
          loading: true,
        });

    const {
      orderTypeArr,
      paymentType,
      shiprocketStatusArr,
      hubId,
      hubStatus,
      ...filterData
    } = this.props.filterData;
    let page = this.findGetParameter("page");
    let params = {
      ...filterData,
      paymentType: paymentType.length ? paymentType.join() : undefined,
      page,
      size: this.findGetParameter("size"),
      status: 1,
    };
    if (orderTypeArr.length) {
      params.orderType = orderTypeArr.map((type) => type.value).join();
    }
    if (hubId.length) {
      params.hubId = hubId.map((type) => type.value).join();
      params.selectedHubs = hubId
      this.setState({
        ...this.state,
        selectedHubs:hubId
      })
    }
    if (hubStatus.length) {
      params.hubStatus = hubStatus.map((type) => type.value).join();
      params.selectedHubStatus = hubStatus
      this.setState({
        ...this.state,
        selectedHubStatus:hubStatus,
      })
    }
    try {
      const {size, status, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType, hubStatus, hubId,} = params;

      let response = await filterColumn({size, status, page, startDate, endDate, prescription, coldChain, imgUrls, paymentType: paymentTypeData, orderType, hubStatus, hubId,});

      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          prevPage: page, 
          params: { ...params, orderTypeArr, paymentType, shiprocketStatusArr},
          page: "underProcess",
        });
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
          modal: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  }
};

  render() {
    return (
      <div id="animated" className="fade-in">
        <div className="row">
          <div className="col-md-6">
            <input
              className="search-field"
              onChange={debounce(this.handleSearch, 500)}
              type="text"
              placeholder="Search"
              defaultValue={
                this.findGetParameter("search") != null
                  ? this.findGetParameter("search")
                  : null
              }
            />
            <div className="search-icon">
              <img src={require("../../../assets/images/search.png")} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="order-filter">
              <div class="filter-column">
                <button type="button" className="filterbtn" onClick={this.toggle}><BsFilter />&nbsp;&nbsp;Filter</button>
                <Modal isOpen={this.state.modal} toggle={()=>this.reIntialFilter()} className={this.props.className}>
                  <ModalHeader toggle={()=>this.reIntialFilter()}>Filter</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <span>StartDate</span>
                      <input
                        type="date"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.startDate}
                        onChange={this.handleFilterChange}
                      />
                      <span>End Date</span>
                      <input
                        type="date"
                        name="endDate"
                        min={this.props.filterData.startDate}
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.endDate}
                        onChange={this.handleFilterChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Prescription</Label>
                      <Input
                        type="select"
                        name="prescription"
                        id="exampleSelect"
                        value={this.props.filterData.prescription}
                        onChange={this.handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Cold Chain</Label>
                      <Input
                        type="select"
                        name="coldChain"
                        id="exampleSelect1"
                        value={this.props.filterData.coldChain}
                        onChange={this.handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Photos</Label>
                      <Input
                        type="select"
                        name="imgUrls"
                        id="exampleSelect2"
                        value={this.props.filterData.imgUrls}
                        onChange={this.handleFilterChange}
                      >
                        <option value="">All</option>
                        <option value="General+New">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Payment Type</Label>
                      <Multiselect
                        isObject={false}
                        options={this.state.paymentFilter.map(data => data._id)}
                        selectedValues={this.props.filterData.paymentType}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "paymentType" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "paymentType" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">OrderType</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.ordertypeoptions}
                        selectedValues={this.props.filterData.orderTypeArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Hub ID</Label>
                      <Multiselect
                        isObject={true}
                        options={this.props.hubNameData}
                        selectedValues={this.props.filterData.hubId}
                        showCheckbox={true}
                        onSelect={(e) =>
                          this.handleFilterChange({
                            target: { value: e, name: "hubId" },
                          })
                        }
                        onRemove={(e) =>
                          this.handleFilterChange({
                            target: { value: e, name: "hubId" },
                          })
                        }
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Hub Status</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.hubStatusOptions}
                        selectedValues={this.props.filterData.hubStatus}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "hubStatus" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "hubStatus" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="cancelfilterbtn" onClick={()=>{this.props.setFilterState(this.initialFilterData); this.props.setOrdersList({params:{selectedHubs:undefined}})}}>Clear Filter</Button>
                    <Button className="applyfilterbtn" onClick={() => this.handlePageClick({ selected: 0 }) && this.filterTypedata()}>Apply Filter</Button>{' '}
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {this.state.nullHubs == 0 ?
           <div className="col-md-12">
              <div className="list-box">
                <table className="w-100 under-process-table">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>
                        Customer Name
                        <br />
                        User Name
                      </th>
                      <th>
                        Old/New
                        <br />
                        User
                      </th>
                      <th>
                        Order Date
                        <br/>
                        Order Time
                      </th>

                      <th>Duration</th>
                      <th>Rx</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>Payment Type</th>
                      <th>Order Type</th>
                      <th>Amount</th>
                      <th>Hub ID</th>
                      <th>Hub Status</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="table_data">
                        <td colSpan="15">
                            <span id="no-record">No Record Found</span>
                        </td>
                      </tr>
                  </tbody>
                </table>
                  </div>
            </div>
          : this.state.loading ? (
            <div className="page-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="list-box">
                <table className="w-100 under-process-table">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>
                        Order Date
                        <br/>
                        Order Time
                      </th>

                      <th>Duration</th>
                      <th>Rx</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>
                        <UncontrolledButtonDropdown>
                          <DropdownToggle tag="span" data-toggle="dropdown">
                            <span style={{ cursor: "pointer" }}>
                              Payment Type
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <div className="payment-filter">
                              {this.state.paymentFilter.map((list, index) => {
                                return (
                                  <div key={index}>
                                    <input
                                      type="checkbox"
                                      name="payment"
                                      className="custom-checkbox"
                                      value={list._id}
                                      checked={this.state.paymentType.includes(
                                        list._id
                                      )}
                                      onChange={this.paymentFilter}
                                    />
                                    <label>{list._id}</label>
                                  </div>
                                );
                              })}
                            </div>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </th>
                      <th>OrderType</th>
                      <th>Amount</th>

                      <th>Hub ID</th>
                      <th>Hub Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ordersList.list.length > 0 ? (
                      this.props.ordersList.list.map((value, index) => {
                        return (
                          <tr key={index} className="table_data">
                            <td>
                              {this.findGetParameter("page") == 1
                                ? index + 1
                                : Number(
                                  (this.findGetParameter("page") - 1) *
                                  this.findGetParameter("size")
                                ) + Number(index + 1)}
                            </td>
                            <td>
                              {(getPermission("Order Management", "Under Process", "View") || getPermission("Order Management", "Under Process", "Edit")) ? <NavLink
                                id="navlink"
                                to={
                                  value.items.length == 0
                                  ? `/admin/ordermanagement/add?id=${value._id}&tab=underProcess`
                                    : `/admin/orderManagement/details?id=${value._id}&tab=underProcess`
                                }
                              >
                                <span className="view-request">
                                  {value.orderId}
                                </span>
                              </NavLink> : value.orderId}
                            </td>
                            <td>
                              {value.addressName}
                              <br />
                              {value.user && (
                                <>{(getPermission("User Data", "View") || getPermission("User Data", "Edit")) ? <NavLink
                                  id="navlink"
                                  to={`/admin/userdata/details?id=${value.userId}`}
                                >
                                  <span className="view-request">
                                    {value.user.name}
                                  </span>
                                </NavLink> : value.user.name}
                              </>)}{" "}
                            </td>
                            <td>
                              <span
                                className={
                                  value.isExistingUser ? "green-dot" : "red-dot"
                                }
                              ></span>
                            </td>
                            <td>
                              {String(value.orderStartDate).split(",")[0]}<br/>
                              {String(value.orderStartDate).split(",")[1]}
                            </td>
                            <td>
                              <span>{dateDiff(value.orderStartDate)}</span>
                            </td>
                            <td>
                              {value.prescriptionUrls.length !== 0 ? (
                                value.prescriptionAccepted ? (
                                  <span id="reviewed">R</span>
                                ) : (
                                  <span id="not-reviewed">---</span>
                                )
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                            <td>
                              {value.items.some((list) => {
                                return list.coldChain == "Yes";
                              }) ? (
                                <img
                                  src={require("assets/images/cold-chain.svg")}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{isGeneralImage(value)}</td>
                            <td>
                              {value.paymentType != null &&
                                value.paymentStatus == 2 &&
                                value.paymentType != "COD" ? (
                                <span>{value.paymentType}</span>
                              ) : value.paymentType == "COD" ? (
                                <span>COD</span>
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                            <td>
                              {value.orderType == 1
                                ? <span>Cart Flow</span>
                                : value.orderType == 3
                                  ? <span>Manual</span>
                                  : <span>Upload Rx</span>}
                            </td>
                            <td>
                              {value.paymentType == "COD" && value.paymentStatus == 0 ?
                                (
                                  <span className="unpaid-order">
                                    Rs.{Number(value.totalPrice).toFixed(2)}
                                  </span>
                                ) :
                                value.paymentType == "COD" && value.paymentStatus == 2 ?
                                  (
                                    <span className="paid-order">
                                      Rs.{Number(value.totalPrice).toFixed(2)}
                                    </span>
                                  ) :
                                  value.paymentStatus == 2 ? (
                                    <span className="paid-order">
                                      Rs.{Number(value.totalPrice).toFixed(2)}
                                    </span>
                                  ) : (
                                    <span className="unpaid-order">
                                      Rs.{Number(value.totalPrice).toFixed(2)}
                                    </span>
                                  )}
                            </td>
                            <td>{value.hubCode}</td>
                            <td>{value.hubStatus === 0 ? "Unaccepted" :
                                value.hubStatus === 1 ? "Accepted" :
                                value.hubStatus === 2 ? "Rejected" :""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table_data">
                        <td colSpan="15">
                          {this.state.search != "" ? (
                            <span id="no-record">No Record Found</span>
                          ) : (
                            <span id="no-record">Under process is empty</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Modal
                  isOpen={this.state.paymentModal}
                  toggle={() => this.paymentToggle()}
                >
                  <ModalBody>
                    <div className="payment-modal">
                      <div className="confirm-message">
                        <b>Are you sure to change the payment type to COD?</b>
                      </div>
                      <div className="confirm-btn">
                        <button onClick={() => this.changePaymentStatus()}>
                          Yes
                        </button>
                        <button onClick={() => this.paymentToggle()}>No</button>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={this.state.openModal}
                  toggle={() => this.openModal()}
                >
                  <ModalBody>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="update-title">
                          <span>Completed Details</span>
                        </div>
                        <div
                          className="close-modal-icon"
                          onClick={() => this.openModal()}
                        >
                          <span>&#x2715;</span>
                        </div>
                      </div>
                      <div className="col-md-8 offset-md-2">
                        <div className="modal-field">
                          <span>Delivered On</span>
                          <input
                            type="date"
                            name="deliveredOn"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-8 offset-md-2">
                        <div className="modal-field">
                          <span>Delivered By</span>
                          <input
                            type="text"
                            placeholder="Enter here"
                            name="deliveredBy"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="align-center">
                          <button
                            className="btn-update"
                            onClick={() => this.handleUpdate()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
              {this.state.maxPages > 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <LimitFilter
                      value={this.findGetParameter("size")}
                      onChange={this.handleLimit}
                    />
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"previous-page"}
                      previousLinkClassName={"previous-link"}
                      nextLabel={"Next"}
                      nextClassName={"previous-page"}
                      nextLinkClassName={"previous-link"}
                      breakLabel={"..."}
                      breakClassName={"break"}
                      breakLinkClassName={"break-link"}
                      pageCount={this.state.maxPages}
                      forcePage={parseInt(this.findGetParameter("page")) - 1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"inactive-page"}
                      pageLinkClassName={"inactive-link"}
                      activeLinkClassName={"active-link"}
                      activeClassName={"active-page"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span id="total-pages">
                      Total Pages : {this.state.maxPages}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}

const mapDispatchToProps = dispatch => ({
  setOrdersList: ordersList => dispatch(setOrdersList(ordersList)),
  clearFilterHandler: () => dispatch(clearFilterHandler())
})



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderProcess);
