export const newOrdersFilter = {
    startDate: "", endDate: "", prescription: "", coldChain: "", imgUrls: "", paymentType: [], orderType: "", orderTypeArr: [], status: 0
};

export const underProcessFilter = {
    startDate: "", endDate: "", prescription: "", coldChain: "", imgUrls: "", paymentType: [], orderType: "", orderTypeArr: [], hubStatus: [], hubId: [], status: 1, selectedHubs: [],
};

export const completedOrderFilter = {
    startDate: "", endDate: "", coldChain: "", imgUrls: "", orderType: "", orderTypeArr: [], courierName: '', shiprocketStatus:"", shiprocketStatusArr: [], status: 2, hubStatus: [], hubId: [], selectedHubs: [],
};

export const cancelledOrderFilter = {
    startDate: "", endDate: "", orderType: "", orderTypeArr: [], status: 4
};

export const leadsFilter = {
    startDate: "", endDate: "", prescription: "", coldChain: "", imgUrls: "", orderType: "", orderTypeArr: [], status: 5
};

export const returnsFilter = {
    startDate: "", endDate: "", coldChain: "", imgUrls: "", paymentType: [], orderType: "", orderTypeArr: [], status: 8
};