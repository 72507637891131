import axios from "axios";
import {getToken} from "../helpers"
import { endpoints } from "config/api";

export const getAllNotification = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.notification.GET_ALL_NOTIFICATION,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const updateNotification = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.notification.UPDATE_NOTIFICATION,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};
