import React from "react";
import OrderManagementComponent from "component/OrderManagement/index";
import { connect } from "react-redux";

const OrderManagement = (props) => {
  return <OrderManagementComponent ordersList={props.ordersList}></OrderManagementComponent>;
};
function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}
const mapDispatchToProps = dispatch => ({
  //clearFilterHandler: () => dispatch(clearFilterHandler())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderManagement);
