import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ReactPaginate from "react-paginate";
import {Toast} from "service/toast";
import {filterColumn, getAllOrder, paymentDropDown} from "service/Order";
import {dateDiff, getPermission, history, isGeneralImage} from "helpers";
import {debounce} from "debounce";
import LimitFilter from "component/common/LimitFilter";
import {connect} from "react-redux";
import {setOrdersList} from "action/home";

import "../Completed/style.scss";
import Multiselect from 'multiselect-react-dropdown';
import {BsFilter} from "react-icons/bs";
import {getUserHubs} from "../../../service/Hub";

class Completed extends Component {
  constructor(props) {
    super(props);
    this.initialFilterData = {
      startDate: "", 
      endDate: "", 
      coldChain: "",
      imgUrls: "", 
      orderType: "",
      orderTypeArr: "",
      courierName: "",
      shiprocketStatus: "",
      shiprocketStatusArr: [],
      hubStatus: [],
      hubId: [],
      selectedHubs:[],
      selectedHubStatus:[]
    }
    this.state = {

      maxPages: "",
      paymentFilter: [],
      openModal: false,
      loading: true,
      ordertypeoptions: [{ name: "Cart Flow", value: "1" }, { name: "Upload Rx", value: "0" }, { name: "Manual", value: "3" }],
      statusOptions: ["Order Confirmed", "Order Processing", "Shipped","PICKED UP",
        "IN TRANSIT", "Delivered", "OUT FOR DELIVERY", "UNDELIVERED", "Order Cancelled"],

      hubNameData: [],
      nullHubs: 1,
      hubStatusOptions: [{ name: "Unaccepted", value: "0"}, { name: "Accepted", value: "1"}, { name: "Rejected", value: "2"}],
      selectedHubs:this.props.ordersList.params.selectedHubs || [],
      selectedHubStatus:this.props.ordersList.params.selectedHubStatus || [],
    };
  }
  async componentDidMount() {
    await this.getAllHub();
    this.setState({
      loading:false
    })
    // alert(JSON.stringify(this.props.ordersList.params.selectedHubs))
    // alert(JSON.stringify(this.state.hubNameData))
    this.props.setFilterState({
      ...this.props.filterData,
      ...this.props.ordersList.params,
      startDate: this.props.ordersList.params.startDate || "",
        endDate: this.props.ordersList.params.endDate || "",
        coldChain: this.props.ordersList.params.coldChain || "",
        imgUrls: this.props.ordersList.params.imgUrls || "",
        orderType: this.props.ordersList.params.orderType || "",
        orderTypeArr: this.props.ordersList.params.orderTypeArr || [],
        courierName: this.props.ordersList.params.courierName || [],
        shiprocketStatus: this.props.ordersList.params.shiprocketStatus || "",
        shiprocketStatusArr: this.props.ordersList.params.shiprocketStatusArr || [],
      hubId: this.state.hubNameData,
      hubStatus:  this.props.filterData.hubStatus || [],
    });

    await this.getCompleted();
    window.scrollTo(0, 0);
    window.addEventListener("popstate", this.getCompleted);
    await this.getPaymentTypes();
  }
  componentWillUnmount() {
    window.removeEventListener("popstate", this.getCompleted);
  }
  getAllHub = async () => {
    try {
      const userId = localStorage.getItem('userId');
      let response = await getUserHubs(userId);

      if (response.status == 200) {
        this.setState({
          hubNameData: response.data.data.map((item) => ({
            name: `${item.name} (${item.code})`,
            value: item._id,
          })),
          //loading: false,
        });
      }
    } catch (error) {
      if(error.response){
        Toast({ type: "error", message: error.response.data.message });
      }
    }
  };
  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  getCompleted = async () => {
    if(this.state.hubNameData.length == 0){
      this.setState({
        nullHubs:0,
        loading: false,
          modal: false,
      });
    }
    else{
      this.setState({
        nullHubs:1,
        loading: true,
        modal: false,
      });
      const { orderTypeArr, paymentType,shiprocketStatusArr,hubId, hubStatus,...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    
    let params = {
      ...filterData,
      size: this.findGetParameter("size"),
      page,
      status: 2,
      
      search: this.findGetParameter("search")
        ? this.findGetParameter("search")
        : null,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    params.shiprocketStatus = shiprocketStatusArr.length ? shiprocketStatusArr.join() : ""
    if (hubId && hubId.length) {
      params.hubId = hubId.map((type) => type.value).join();
      params.selectedHubs = hubId
      this.setState({
        ...this.state,
        selectedHubs:hubId
      })
    }
    if (hubStatus.length>0) {
      params.hubStatus = hubStatus.map((type) => type.value).join();
      params.selectedHubStatus = hubStatus

      this.props.setFilterState({...this.props.filterData, hubId});
    }
    try {
      const { size, search, status,startDate, endDate, coldChain, imgUrls, courierName, shiprocketStatus, orderType, hubStatus} = params
      let response = await getAllOrder({page, size, search, status,startDate, endDate, coldChain, imgUrls, courierName, shiprocketStatus, orderType, hubId:this.props.filterData.hubId.map((item)=>{
        return item.value
       }).join(),
       hubStatus});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          page: "completed",
          prevPage: page,
          params: { ...params, orderTypeArr, shiprocketStatusArr }
        })
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
        });
      }
    } catch (error) {
      if(error.response) {
        Toast({ type: "error", message: error.response.data.message });
      } else {
        Toast({ type: "error", message: error.message });
      }
    }
    }

  };
  getPaymentTypes = async () => {
    try {
      let response = await paymentDropDown();
      if (response.status == 200) {
        this.setState({
          paymentFilter: response.data.data,
        });
      }
    } catch (error) {
      if(error.response) {
        Toast({ type: "error", message: error.response.data.message });
      }
    }
  };
  handlePageClick = (event) => {
    if (this.findGetParameter("search") == null) {
      history.push(
        "/admin/ordermanagement/list?tab=completed&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({

        loading: true,
      });
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=completed&search=" +
        this.findGetParameter("search") +
        "&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );

      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({

        loading: true,
      });
    }
  };
  handleSearch = async (event) => {
    if (event.target.value == "") {
      await history.push(
        "/admin/ordermanagement/list?tab=completed&page=1&size=" +
        this.findGetParameter("size")
      );
      this.setState(
        {
          loading: true,

        },
        () => {
          setTimeout(() => {
            this.getCompleted();
          }, 1000);
        }
      );
    } else {
      await history.push(
        `/admin/ordermanagement/list?tab=completed&search=${String(
          event.target.value
        )
          .replace("#", "")
          .trim()}&page=1&size=${this.findGetParameter("size")}`
      );
      this.setState(
        {
          loading: true,
          maxPages: 1,

        },
        () => {
          setTimeout(() => {
            this.getCompleted();
          }, 1000);
        }
      );
    }
  };

  handleDateFilter = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          this.props.onDateChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        }
      }
    );
  };

  handleLimit = (event) => {
    if (this.findGetParameter("search") != null) {
      history.push(
        "/admin/ordermanagement/list?tab=completed&page=1" +
        "&size=" +
        event +
        "&search=" +
        this.findGetParameter("search")
      );
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=completed&page=1" + "&size=" + event
      );
    }
    this.getCompleted();
  };
  reIntialFilter(){
    this.toggle()
  }

  //Modal open Function
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleFilterChange = (e) => {
    this.props.onFilterChange(e);
  }
  //Filter column function
  filterTypedata = async () => {
    if (this.props.filterData.hubId.length === 0){
      this.setState({
        nullHubs:0,
        loading: true,
          modal: false,
      });
    }
      else{
        await this.getAllHub();
        this.setState({
          nullHubs:1,
          modal: false,
          loading: true,
        });
        const { orderTypeArr,shiprocketStatusArr, hubId, hubStatus, ...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      page,
      size: this.findGetParameter("size"),
      status: 2,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    params.shiprocketStatus = shiprocketStatusArr.length ? shiprocketStatusArr.join() : ""
    if (hubId.length) {
      params.hubId = hubId.map((type) => type.value).join();
      params.selectedHubs = hubId
      this.setState({
        ...this.state,
        selectedHubs:hubId
      })
    }
    if (hubStatus.length) {
      params.hubStatus = hubStatus.map((type) => type.value).join();
      params.selectedHubStatus = hubStatus
      this.setState({
        ...this.state,
        selectedHubStatus:hubStatus,
      })
    }
    try {
      const { size, status,startDate, endDate, coldChain, imgUrls, courierName, shiprocketStatus, orderType, hubStatus, hubId,} = params
      let response = await filterColumn({page, size, status,startDate, endDate, coldChain, imgUrls, courierName, shiprocketStatus, orderType, hubStatus, hubId,});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          params: { ...params, orderTypeArr, shiprocketStatusArr, hubId, hubStatus},
          prevPage: page,
          page: "completed"
        });
        this.setState({
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
          modal: false,
        });
      }
    } catch (error) {
      if(error.response) {
        Toast({ type: "error", message: error.response.data.message });
      }

    }
  }


  }

  render() {
    return (
      <div id="animated" className="fade-in">
        <div className="row">
          <div className="col-md-6">
            <input
              className="search-field"
              onChange={debounce(this.handleSearch, 500)}
              type="text"
              placeholder="Search"
              defaultValue={
                this.findGetParameter("search") != null
                  ? this.findGetParameter("search")
                  : null
              }
            />
            <div className="search-icon">
              <img src={require("../../../assets/images/search.png")} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="order-filter">
              <div class="filter-column">
                <button type="button" className="filterbtn" onClick={this.toggle}><BsFilter />&nbsp;&nbsp;Filter</button>
                <Modal isOpen={this.state.modal} toggle={()=>this.reIntialFilter()} className={this.props.className}>
                  <ModalHeader toggle={()=>this.reIntialFilter()}>Filter</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <span>StartDate</span>
                      <input
                        type="date"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.startDate}
                        onChange={this.handleFilterChange}
                      />
                      <span>End Date</span>
                      <input
                        type="date"
                        name="endDate"
                        min={this.props.filterData.startDate}
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.endDate}
                        onChange={this.handleFilterChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Cold Chain</Label>
                      <Input
                        type="select"
                        name="coldChain"
                        id="exampleSelect1"
                        value={this.props.filterData.coldChain}
                        onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Photos</Label>
                      <Input
                        type="select"
                        name="imgUrls"
                        id="exampleSelect2"
                        value={this.props.filterData.imgUrls}
                        onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="General+New">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Courier</Label>
                      <Input
                        type="text"
                        name="courierName"
                        id="examplePassword"
                        placeholder="Enter Courier"
                        value={this.props.filterData.courierName}
                        onChange={this.handleFilterChange} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Status</Label>
                      <Multiselect
                        isObject={false}
                        options={this.state.statusOptions}
                        selectedValues={this.props.filterData.shiprocketStatusArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "shiprocketStatusArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "shiprocketStatusArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">OrderType</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.ordertypeoptions}
                        selectedValues={this.props.filterData.orderTypeArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Hub ID</Label>
                      <Multiselect
                        isObject={true}
                        options={this.props.hubNameData}
                        selectedValues={this.props.filterData.hubId}
                        showCheckbox={true}
                        onSelect={(e) =>
                          this.handleFilterChange({
                            target: { value: e, name: "hubId" },
                          })
                        }
                        onRemove={(e) =>
                          this.handleFilterChange({
                            target: { value: e, name: "hubId" },
                          })
                        }
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Hub Status</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.hubStatusOptions}
                        selectedValues={this.props.filterData.hubStatus}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "hubStatus" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "hubStatus" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="cancelfilterbtn" onClick={() => {this.props.setFilterState(this.initialFilterData); this.props.setOrdersList({params:{selectedHubs:undefined}})}}>Clear Filter</Button>
                    <Button className="applyfilterbtn" onClick={() => this.handlePageClick({ selected: 0 }) && this.filterTypedata()}>Apply Filter</Button>{' '}
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {this.state.nullHubs == 0 ?
           <div className="col-md-12">
              <div className="list-box">
                <table className="under-process-table w-100">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>Date <br/>Time</th>
                      <th>Duration</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>Amount</th>
                      <th>Courier</th>
                      <th>Status</th>
                      <th>OrderType</th>
                      <th>Hub ID</th>
                      <th>Hub Status</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr className="table_data">
                        <td colSpan="15">
                            <span id="no-record">No Record Found</span>
                        </td>
                      </tr>
                  </tbody>
                </table>
                  </div>
            </div>
          : this.state.loading ? (
            <div className="page-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="list-box">
                <table className="completed-order-table w-100">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>Date <br/>Time</th>
                      <th>Duration</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>Amount</th>
                      <th>Courier</th>
                      <th>Status</th>
                      <th>OrderType</th>
                      <th>Hub ID</th>
                      <th>Hub Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ordersList.list.length > 0 ? (
                      this.props.ordersList.list.map((value, index) => {
                        return (
                          <tr key={index} className="table_data">
                            <td>
                              {this.findGetParameter("page") == 1
                                ? index + 1
                                : Number(
                                  (this.findGetParameter("page") - 1) *
                                  this.findGetParameter("size")
                                ) + Number(index + 1)}
                            </td>
                            <td>
                              {(getPermission("Order Management", "Completed", "View")||getPermission("Order Management", "Completed", "Edit")) ? <NavLink
                                id="navlink"
                                to={
                                  value.items.length === 0
                                    ? `/admin/ordermanagement/add?id=${value._id}&tab=completed`
                                    : `/admin/orderManagement/details?id=${value._id}&tab=completed`
                                }
                              >
                                <span className="view-request">
                                  {value.orderId}
                                </span>
                              </NavLink> : value.orderId}
                            </td>
                            <td>
                              {value.addressName}
                              <br />
                              {value.user && (
                                <>{(getPermission("User Data", "View") || getPermission("User Data", "Edit")) ? <NavLink
                                  id="navlink"
                                  to={`/admin/userdata/details?id=${value.userId}`}
                                >
                                  <span className="view-request">
                                    {value.user.name}
                                  </span>
                                </NavLink> : value.user.name}
                              </>)}{" "}
                            </td>
                            <td>
                              <span
                                className={
                                  value.isExistingUser ? "green-dot" : "red-dot"
                                }
                              ></span>
                            </td>
                            <td>
                              {String(value.orderStartDate).split(",")[0]} <br/> {String(value.orderStartDate).split(",")[1]}
                            </td>
                            <td>
                              {!value.orderEndDate ? (
                                <span>{dateDiff(value.orderStartDate)}</span>
                              ) : (
                                <span>
                                  {dateDiff(
                                    value.orderStartDate,
                                    value.orderEndDate
                                  )}
                                </span>
                              )}
                            </td>
                            <td>
                              {value.items.some((list) => {
                                return list.coldChain == "Yes";
                              }) ? (
                                <img
                                  src={require("assets/images/cold-chain.svg")}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{isGeneralImage(value)}</td>
                            <td>
                              {value.paymentType == "COD" && value.paymentStatus == 2 ?
                                (<span style={{ color: "#67ae3f" }}>
                                  Rs. {Number(value.totalPrice).toFixed(2)}
                                </span>) :
                                value.paymentType == "COD" && value.paymentStatus == 0 ?
                                  (<span style={{ color: "#ff4f5b" }}>
                                    Rs. {Number(value.totalPrice).toFixed(2)}
                                  </span>) :
                                  value.paymentStatus == 2 ? (
                                    <span style={{ color: "#67ae3f" }}>
                                      Rs.{Number(value.totalPrice).toFixed(2)}
                                    </span>
                                  ) : (
                                    <span style={{ color: "#ff4f5b" }}>
                                      Rs. {Number(value.totalPrice).toFixed(2)}
                                    </span>
                                  )}
                            </td>
                            <td>
                              {value.courierName && value.courierName != null
                                ? value.courierName
                                : "--"}
                            </td>
                            <td>{value.shiprocketStatus}</td>
                            <td>
                              {value.orderType == 1
                                ? <span>Cart Flow</span>
                                : value.orderType == 3
                                  ? <span>Manual</span>
                                  : <span>Upload Rx</span>}
                            </td>
                            <td>{value.hubCode}</td>
                            <td>{value.hubStatus === 0 ? "Unaccepted" :
                                value.hubStatus === 1 ? "Accepted" :
                                value.hubStatus === 2 ? "Rejected" :""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table_data">
                        <td colSpan="15">
                          {this.state.search != "" ? (
                            <span id="no-record">No Record Found</span>
                          ) : (
                            <span id="no-record">Completed is empty</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.maxPages > 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <LimitFilter
                      value={this.findGetParameter("size")}
                      onChange={this.handleLimit}
                    />
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"previous-page"}
                      previousLinkClassName={"previous-link"}
                      nextLabel={"Next"}
                      nextClassName={"previous-page"}
                      nextLinkClassName={"previous-link"}
                      breakLabel={"..."}
                      breakClassName={"break"}
                      breakLinkClassName={"break-link"}
                      forcePage={parseInt(this.findGetParameter("page")) - 1}
                      pageCount={this.state.maxPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"inactive-page"}
                      pageLinkClassName={"inactive-link"}
                      activeLinkClassName={"active-link"}
                      activeClassName={"active-page"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span id="total-pages">
                      Total Pages : {this.state.maxPages}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}

const mapDispatchToProps = dispatch => ({
  setOrdersList: ordersList => dispatch(setOrdersList(ordersList)),
})



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Completed)
