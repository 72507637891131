import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup, Label, Button
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { history, isGeneralImage, getPermission } from "helpers";
import { debounce } from "debounce";
import { Toast } from "service/toast";
import { getAllOrder, filterColumn } from "service/Order";
import LimitFilter from "component/common/LimitFilter";
import { connect } from "react-redux";
import { setOrdersList } from "action/home";
import "../Leads/style.scss";
import Multiselect from 'multiselect-react-dropdown';
import { BsFilter } from "react-icons/bs";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.initialFilterData = {
      startDate: "", 
      endDate: "", 
      prescription: "",
      coldChain: "",
      editPermission: getPermission("Order Management", "Leads", "Edit"),
      imgUrls: "", 
      orderType: "",
      orderTypeArr: []
    }
    this.state = {
      // leads: [],
      maxPages: "",
      loading: true,
      isOpen: false,
      viewAccess: (getPermission("Order Management", "Leads", "View") || getPermission("Order Management", "Leads", "Edit")),
      ordertypeoptions: [{ name: "Cart Flow", value: "1" }, { name: "Upload Rx", value: "0" }, { name: "Manual", value: "3" }],
      /*filterData: {
        startDate: this.props.ordersList.params.startDate || "", 
        endDate: this.props.ordersList.params.endDate || "", 
        prescription: this.props.ordersList.params.prescription || "",
        coldChain: this.props.ordersList.params.coldChain || "",
        imgUrls: this.props.ordersList.params.imgUrls || "", 
        orderType: this.props.ordersList.params.orderType || "",
        orderTypeArr: this.props.ordersList.params.orderTypeArr || []
      }*/
    };
  }
  async componentDidMount() {
    await this.getLeads();
    window.scrollTo(0, 0);
    window.addEventListener("popstate", this.getLeads);
  }
  componentWillUnmount() {
    window.removeEventListener("popstate", this.getLeads);
  }
  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  getLeads = async () => {
    const { orderTypeArr, shiprocketStatusArr,  ...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      page,
      size: this.findGetParameter("size"),
      status: 5,
      
      search: this.findGetParameter("search")
        ? this.findGetParameter("search")
        : null,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    try {
      const {size, status, search, startDate, endDate, prescription, coldChain, imgUrls, orderType} = params
      let response = await getAllOrder({size, status, search, page, startDate, endDate, prescription, coldChain, imgUrls, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          prevPage: page,
          page: "leads",
          params: { ...params, orderTypeArr, shiprocketStatusArr }
        })
        this.setState({
          // leads: response.data.data.list,
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  };

  handlePageClick = (event) => {
    if (this.findGetParameter("search") == null) {
      history.push(
        "/admin/ordermanagement/list?tab=leads&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      // this.getLeads();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        // leads: [],
        loading: true,
      });
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=leads&search=" +
        this.findGetParameter("search") +
        "&page=" +
        parseInt(event.selected + 1) +
        "&size=" +
        this.findGetParameter("size")
      );
      this.getLeads();
      this.filterTypedata();
      window.scrollTo(0, 0);
      this.setState({
        leads: [],
        loading: true,
      });
    }
  };
  handleSearch = async (event) => {
    if (event.target.value === "") {
      await history.push(
        "/admin/ordermanagement/list?tab=leads&page=1&size=" +
        this.findGetParameter("size")
      );
      this.setState(
        {
          // leads: [],
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getLeads();
          }, 1000);
        }
      );
    } else {
      await history.push(
        "/admin/ordermanagement/list?tab=leads&search=" +
        String(event.target.value).replace("#", "").trim() +
        "&page=1&size=" +
        this.findGetParameter("size")
      );
      this.setState(
        {
          // leads: [],
          maxPages: 1,
          loading: true,
        },
        () => {
          setTimeout(() => {
            this.getLeads();
          }, 1000);
        }
      );
    }
  };

  handleLimit = (event) => {
    if (this.findGetParameter("search") != null) {
      history.push(
        "/admin/ordermanagement/list?tab=leads&page=1&paymentType=" +
        "&size=" +
        event +
        "&search=" +
        this.findGetParameter("search")
      );
    } else {
      history.push(
        "/admin/ordermanagement/list?tab=leads&page=1&paymentType=" +
        "&size=" +
        event
      );
    }
    this.getLeads();
  };
  reIntialFilter(){
    this.toggle()
    this.setState({filterData: {
      startDate: this.props.ordersList.params.startDate || "", 
      endDate: this.props.ordersList.params.endDate || "", 
      prescription: this.props.ordersList.params.prescription || "",
      coldChain: this.props.ordersList.params.coldChain || "",
      imgUrls: this.props.ordersList.params.imgUrls || "", 
      orderType: this.props.ordersList.params.orderType || "",
      orderTypeArr: this.props.ordersList.params.orderTypeArr || []
    }})
  }
  //Modal open Function
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  handleFilterChange = (e) => {

    this.props.onFilterChange(e);

    /*this.setState({
      filterData: {
        ...this.state.filterData,
        [e .target.name]: e.target.value
      }
    })*/
  }
  //Filter column function
  filterTypedata = async () => {
    const { orderTypeArr, shiprocketStatusArr,  ...filterData } = this.props.filterData
    let page = this.findGetParameter("page")
    let params = {
      ...filterData,
      page,
      size: this.findGetParameter("size"),
      status: 5,
    };
    params.orderType = orderTypeArr.length ? orderTypeArr.map(type => type.value).join() : ""
    try {
      const {size, status,startDate, endDate, prescription, coldChain, imgUrls, orderType} = params
      let response = await filterColumn({page, size, status,startDate, endDate, prescription, coldChain, imgUrls, orderType});
      if (response.status == 200) {
        this.props.setOrdersList({
          ...response.data.data,
          prevPage: page,
          page: "leads",
          params: { ...params, orderTypeArr,shiprocketStatusArr}
        })
        this.setState({
          // leads: response.data.data.list,
          maxPages: response.data.data.pageMeta.totalPages,
          loading: false,
          modal: false,
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error.response.data.message });
    }
  }

  render() {
    return (
      <div id="animated" className="fade-in">
        <div className="row">
          <div className="col-md-6">
            <input
              className="search-field"
              onChange={debounce(this.handleSearch, 500)}
              type="text"
              defaultValue={
                this.findGetParameter("search") != null
                  ? this.findGetParameter("search")
                  : null
              }
              placeholder="Search"
            />
            <div className="search-icon">
              <img src={require("../../../assets/images/search.png")} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="order-filter">
              <div class="filter-column">
                <button type="button" className="filterbtn" onClick={this.toggle}><BsFilter />&nbsp;&nbsp;Filter</button>
                <Modal isOpen={this.state.modal} toggle={()=>this.reIntialFilter()} className={this.props.className}>
                  <ModalHeader toggle={()=>this.reIntialFilter()}>Filter</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <span>StartDate</span>
                      <input
                        type="date"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.startDate}
                        onChange={this.handleFilterChange}
                      />
                      <span>End Date</span>
                      <input
                        type="date"
                        name="endDate"
                        min={this.props.filterData.startDate}
                        max={new Date().toISOString().split("T")[0]}
                        value={this.props.filterData.endDate}
                        onChange={this.handleFilterChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Prescription</Label>
                      <Input
                        type="select"
                        name="prescription"
                        id="exampleSelect"
                        value={this.props.filterData.prescription}
                        onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Cold Chain</Label>
                      <Input 
                      type="select" 
                      name="coldChain" 
                      id="exampleSelect1" 
                      value={this.props.filterData.coldChain}
                      onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Photos</Label>
                      <Input 
                      type="select" 
                      name="imgUrls" 
                      id="exampleSelect2" 
                      value={this.props.filterData.imgUrls}
                      onChange={this.handleFilterChange}>
                        <option value="">All</option>
                        <option value="General+New">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">OrderType</Label>
                      <Multiselect
                        isObject={true}
                        options={this.state.ordertypeoptions}
                        selectedValues={this.props.filterData.orderTypeArr}
                        showCheckbox={true}
                        onSelect={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        onRemove={(e) => this.handleFilterChange({ target: { value: e, name: "orderTypeArr" } })}
                        displayValue="name"
                        showArrow={true}
                      />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="cancelfilterbtn" onClick={()=> this.props.setFilterState(this.initialFilterData)}>Clear Filter</Button>
                    <Button className="applyfilterbtn" onClick={() => this.handlePageClick({ selected: 0 }) && this.filterTypedata()}>Apply Filter</Button>{' '}
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="page-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="list-box">
                <table className="new-order-management w-100">
                  <thead>
                    <tr className="table_head">
                      <th>S.No</th>
                      <th>Order ID</th>
                      <th>Customer Name<br />User Name</th>
                      <th>Old/New<br />User</th>
                      <th>Order Date <br/> Order Time </th>
                      <th>Rx</th>
                      <th>Cold Chain</th>
                      <th>Photos</th>
                      <th>OrderType</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ordersList.list.length > 0 ? (
                      this.props.ordersList.list.map((value, index) => {
                        return (
                          <tr key={index} className="table_data">
                            <td>
                              {this.findGetParameter("page") == 1
                                ? index + 1
                                : Number(
                                  (this.findGetParameter("page") - 1) *
                                  this.findGetParameter("size")
                                ) + Number(index + 1)}
                            </td>
                            <td>
                              {this.state.viewAccess ? <NavLink
                                id="navlink"
                                to={
                                  value.items.length === 0
                                    ? `/admin/ordermanagement/add?id=${value._id}&tab=leads`
                                    : `/admin/orderManagement/details?id=${value._id}&tab=leads`
                                }
                              >
                                <span className="view-request">
                                  {value.orderId}
                                </span>
                              </NavLink> : value.orderId}
                            </td>
                            <td>
                              {value.addressName}
                              <br />
                              {value.user && (
                                <>{(getPermission("User Data", "View") || getPermission("User Data", "Edit")) ? <NavLink
                                  id="navlink"
                                  to={`/admin/userdata/details?id=${value.userId}`}
                                >
                                  <span className="view-request">
                                    {value.user.name}
                                  </span>
                                </NavLink> : value.user.name}
                              </>)}{" "}
                            </td>
                            <td>
                              <span
                                className={
                                  value.isExistingUser ? "green-dot" : "red-dot"
                                }
                              ></span>
                            </td>
                            <td>
                              {String(value.orderStartDate).split(",")[0]} <br/> {String(value.orderStartDate).split(",")[1]}
                            </td>
                            <td>
                              {value.prescriptionUrls.length !== 0 ? (
                                <>
                                   {this.state.viewAccess ? <NavLink
                                  id="navlink"
                                  to={
                                    value.items.length === 0
                                      ? `/admin/ordermanagement/add?id=${
                                      value._id}&tab=leads` 
                                      : `/admin/ordermanagement/details?id=${
                                      value._id}&tab=leads`
                                  }
                                >
                                  <span className="view-request">View</span>
                                </NavLink> : <span>View</span>}
                                </>
                               
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                            <td>
                              {value.items.some((list) => {
                                return list.coldChain == "Yes";
                              }) ? (
                                <img
                                  src={require("assets/images/cold-chain.svg")}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{isGeneralImage(value)}</td>
                            <td>
                              {value.orderType == 1
                                ? <span>Cart Flow</span>
                                : value.orderType == 3
                                  ? <span>Manual</span>
                                  : <span>Upload Rx</span>}
                            </td>
                            <td>
                              {value.paymentType == "COD" && value.paymentStatus == 0 ?
                                (<span className="unpaid-order">
                                  Rs. {value.totalPrice}
                                </span>) :
                                value.paymentStatus == 0 ? (
                                  <span className="unpaid-order">
                                    Rs. {value.totalPrice}
                                  </span>
                                ) : ""}
                              {value.paymentType == "COD" && value.paymentStatus == 2 ?
                                (<span className="paid-order">
                                  Rs. {value.totalPrice}
                                </span>) :
                                value.paymentStatus == 2 ? (
                                  <span className="paid-order">Rs. {value.totalPrice}</span>
                                ) : ""}
                              {value.paymentStatus == 3 && (
                                <span>Refund Initiated</span>
                              )}
                              {value.paymentStatus == 4 && (
                                <span>Refund Processed</span>
                              )}
                              {value.paymentStatus == 5 && (
                                <span>Refund Failed</span>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="table_data">
                        <td colSpan="11">
                          {this.state.search != "" ? (
                            <span id="no-record">No Record Found</span>
                          ) : (
                            <span id="no-record">Lead order is empty</span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.maxPages > 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <LimitFilter
                      value={this.findGetParameter("size")}
                      onChange={this.handleLimit}
                    />
                    <ReactPaginate
                      previousLabel={"Previous"}
                      previousClassName={"previous-page"}
                      previousLinkClassName={"previous-link"}
                      nextLabel={"Next"}
                      nextClassName={"previous-page"}
                      nextLinkClassName={"previous-link"}
                      breakLabel={"..."}
                      breakClassName={"break"}
                      forcePage={parseInt(this.findGetParameter("page")) - 1}
                      breakLinkClassName={"break-link"}
                      pageCount={this.state.maxPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"inactive-page"}
                      pageLinkClassName={"inactive-link"}
                      activeLinkClassName={"active-link"}
                      activeClassName={"active-page"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span id="total-pages">
                      Total Pages : {this.state.maxPages}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ordersList: state.home.ordersList,
  };
}

const mapDispatchToProps = dispatch => ({
  setOrdersList: ordersList => dispatch(setOrdersList(ordersList)),
})



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leads)
