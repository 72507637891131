import axios from "axios";
import {getToken} from "../helpers"
import { endpoints } from "config/api";

export const getOrderById = async (id) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.order.GET_ORDER_BY_ID}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const getAllOrder = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.order.GET_ALL_ORDER,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const updateOrderLog = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.order.UPDATE_ORDER_LOG,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
}

export const searchProduct = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.products.SEARCH_PRODUCT,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const searchMolecule = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.molecule.SEARCH_MOLECULE,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const orderCancel = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.order.CANCEL_ORDER,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const createOrder = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.order.CREATE_ORDER,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const updateOrder = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.order.UPDATE_ORDER,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const uploadPrescriptionApi  = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.order.UPLOAD_PRESCRIPTION_API,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const deletePrescriptionApi = async (id,Pid) => {
  let request = await axios({
    method: "delete",
    url: `${endpoints.order.DELETE_PRESCRIPTION_API}/${id}/${Pid}`,
     headers: {
       Authorization: getToken(),
     },
  });
  return request;
};

export const userPrescription = async (id) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.order.USER_PRESCRIPTION}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};



export const updateDelivery = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.order.UPDATE_DELIVERY,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const paymentDropDown = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.order.PAYMENT_DROPDOWN,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const addPrescription = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.order.ADD_PRESCRIPTION,
    data: data,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const updatePrescription = async (data) => {
  return await axios({
    method: "put",
    url: endpoints.order.UPDATE_PRESCRIPTION,
    data: data,
    headers: {
      Authorization: getToken(),
    },
  });
};

export const getFeedbackById = async (id) => {
  return await axios({
    method: "get",
    url: `${endpoints.order.GET_FEEDBACK_BY_ID}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
};

export const customMessage = async (params, data) => {
  return await axios({
    method: "post",
    url: endpoints.order.CUSTOM_MESSAGE,
    data: data,
    params: params,
    headers: {
      Authorization: getToken(),
    },
  });
};

export const initiateRefund = async (data) => {
  return await axios({
    method: "post",
    url: endpoints.order.PAYMENT_REFUND,
    data: data,
  });
};

export const pickerrTracking = async (pincode) => {
  return await axios({
    method: "get",
    url: `https://www.pickrr.com/api/check-pincode-service/?from_pincode=600017&to_pincode=${pincode}&auth_token=42e9f56c36fe09badde68d79174c3c47216411`,
  });
};

export const updateOrderAddress = async (data) => {
  return await axios({
    data,
    method: "put",
    url: endpoints.order.UPDATE_ORDER_ADDRESS,
    headers: {
      Authorization: getToken(),
    },
  });
};

export const filterColumn = async (params) => {
  return await axios({
    method: "get",
    url: endpoints.order.FILTER_COLUMN,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
};
export const reAssignHub = async (data) =>{
  return await axios({
    method:'post',
    url:endpoints.order.REASSIGN_HUB,
    headers:{
      Authorization:getToken(),
    },
    data:data,

  })
}
