import axios from "axios";
import {getToken} from "../helpers"
import { endpoints } from "config/api";


export const getAllHubs = async (params) => {
    let request = await axios({
      method: "get",
      url: endpoints.hub.GET_ALL_HUB,
      headers: {
        Authorization: getToken(),
      },
      params: params,
    });
    return request;
};

export const getUserHubs = async(params) => {
  let request = await axios({
    method:"get",
    url:endpoints.staffsAndRoles.GET_STAFF_HUBS+params,
    headers:{
      Authorization: getToken(),
    },
    
  })
  return request;
}

export const createHub = async (data) => {
    let request = await axios({
      method: "post",
      url: endpoints.hub.CREATE_HUB,
      headers: {
        Authorization: getToken(),
      },
      data: data,
    });
    return request;
};

export const updateHub = async (data, id) => {
  let request = await axios({
    method: "post",
    url: `${endpoints.hub.UPDATE_HUB}/${id}`,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const getHubById = async (id) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.hub.GET_HUB_BY_ID}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const deleteHub = async (id) => {
  let request = await axios({
    method: "delete",
    url: `${endpoints.hub.DELETE_HUB}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};