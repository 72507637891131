// DEVELOPMENT

// export const baseURL = "http://doodlebluelive.com";
// export const authURL = `${baseURL}:2315/api/v1`;
// export const adminURL = `${baseURL}:2316/api/v1`;
// export const profileURL = `${baseURL}:2317/api/v1`;
// export const orderURL = `${baseURL}:2318/api/v1`;
// export const productURL = `${baseURL}:2319/api/v1`;
// export const medPlanURL = `${baseURL}:2320/api/v1`;
// export const notificationURL = `${baseURL}:2321/api/v1`;
// export const cmsURL = `${baseURL}:2322/api/v1`;

// PREPROD

export const baseURL = "https://apidev.mrmed.in";
export const authURL = `${baseURL}/auth/api/v1`;
export const adminURL = `${baseURL}/admin/api/v1`;
export const profileURL = `${baseURL}/profiles/api/v1`;
export const orderURL = `${baseURL}/orders/api/v1`;
export const productURL = `${baseURL}/products/api/v1`;
export const medPlanURL = `${baseURL}/medPlans/api/v1`;
export const notificationURL = `${baseURL}/notification/api/v1`;
export const cmsURL = `${baseURL}/cms/api/v1`;

// PRODUCTION

// export const baseURL = "https://api.mrmed.in";
// export const authURL = `${baseURL}/auth/api/v1`;
// export const adminURL = `${baseURL}/admin/api/v1`;
// export const profileURL = `${baseURL}/profiles/api/v1`;
// export const orderURL = `${baseURL}/orders/api/v1`;
// export const productURL = `${baseURL}/products/api/v1`;
// export const medPlanURL = `${baseURL}/medPlans/api/v1`;
// export const notificationURL = `${baseURL}/notification/api/v1`;
// export const cmsURL = `${baseURL}/cms/api/v1`;

export const endpoints = {
  dashboard: {
    GET_REVENUE: `${orderURL}/order/totalRevenue`,
    GET_DASHBOARD: `${orderURL}/order/dashboard`,
    GET_TOPSELLING: `${orderURL}/order/topSpecialityProduct`,
    GET_USERDATA: `${authURL}/user/total`,
  },
  SKUReports: {
    GET_ALL_SKU_REPORTS: `${orderURL}/sku/reports`,
    DOWNLOAD_SKU_REPORTS: `${orderURL}/sku/download`,
  },
  staffsAndRoles: {
    ADD_ROLE: `${authURL}/roles`,
    UPDATE_ROLE: `${authURL}/roles`,
    DELTE_ROLE: `${authURL}/roles`,
    GET_ALL_ROLE: `${authURL}/roles?size=10`,
    GET_ROLE_BY_ID: `${authURL}/roles`,
    ADD_STAFF: `${authURL}/user/signup`,
    UPDATE_STAFF: `${authURL}/staff`,
    DELTE_STAFF: `${authURL}/staff`,
    GET_ALL_STAFF: `${authURL}/staff?size=10`,
    GET_STAFF_BY_ID: `${authURL}/staff`,
    ROLE_DROPDOWN: `${authURL}/roles/getRoleName`,
    GET_STAFF_HUBS: `${authURL}/staff/staffHubs/`,
  },
  faq: {
    ADD_FAQ: `${adminURL}/faq`,
    UPDATE_FAQ: `${adminURL}/faq`,
    DELTE_FAQ: `${adminURL}/faq`,
    GET_ALL_FAQ: `${adminURL}/faq?size=20`,
    GET_FAQ_BY_ID: `${adminURL}/faq`,
    FAQ_PRIORITY: `${adminURL}/faq/updatePriority`,
    FAQ_DROPDOWN: `${adminURL}/faq/group`,
  },
  speciality: {
    ADD_SPECIALITY: `${productURL}/product/speciality`,
    UPDATE_SPECIALITY: `${productURL}/product/speciality`,
    DELTE_SPECIALITY: `${productURL}/product/speciality`,
    GET_ALL_SPECIALITY: `${productURL}/product/speciality`,
    GET_SPECIALITY_BY_ID: `${productURL}/product/speciality`,
    SPECIALITY_PRIORITY: `${productURL}/product/speciality/updatePriority`,
  },
  testimonial: {
    ADD_TESTIMONIAL: `${cmsURL}/cms/testimonial`,
    UPDATE_TESTIMONIAL: `${cmsURL}/cms/testimonial`,
    DELTE_TESTIMONIAL: `${cmsURL}/cms/testimonial`,
    GET_ALL_TESTIMONIAL: `${cmsURL}/cms/testimonial?size=20`,
    GET_TESTIMONIAL_BY_ID: `${cmsURL}/cms/testimonial`,
    TESTIMONIAL_PRIORITY: `${cmsURL}/cms/testimonial/updatePriority`,
    TESTIMONIAL_DROPDOWN: `${cmsURL}/cms/group`,
  },
  news: {
    ADD_NEWS: `${adminURL}/news`,
    UPDATE_NEWS: `${adminURL}/news/update/details`,
    DELETE_NEWS: `${adminURL}/news/delete-news`,
    GET_ALL_NEWS: `${adminURL}/news/get/list`,
    GET_NEWS_BY_ID: `${adminURL}/news`,
    NEWS_PRIORITY: `${adminURL}/news/update/priority`,
    NEWS_DROPDOWN: `${cmsURL}/cms/group`,
  },
  products: {
    ADD_PRODUCT: `${productURL}/product`,
    UPDATE_PRODUCT: `${productURL}/product`,
    GET_PRODUCT_BY_ID: `${productURL}/product`,
    GET_ALL_PRODUCT: `${productURL}/product`,
    SPECIALTIY_DROPDOWN: `${productURL}/product/getSpecialityName`,
    CONDITION_DROPDOWN: `${productURL}/product/getConditionName`,
    MOLECULE_DROPDOWN: `${productURL}/product/getAllMolecules`,
    DELETE_PRODUCT: `${productURL}/product`,
    BULK_UPLOAD: `${productURL}/product/bulk`,
    SEARCH_PRODUCT: `${productURL}/product/getProductName`,
    GET_VOTES: `${productURL}/product/countVote`,
  },
  reviews: {
    GET_OAUTH_URL: `${adminURL}/review/oauth/verification`,
    FETCH_REVIEWS: `${adminURL}/review`,
    GET_ALL_REVIEWS: `${adminURL}/review/all`,
    UPDATE_REVIEW: `${adminURL}/review`,
    UPDATE_REVIEW_CATEGORY: `${adminURL}/review/categoryUpdate`,
  },
  order: {
    GET_ORDER_BY_ID: `${orderURL}/order`,
    UPDATE_ORDER: `${orderURL}/order`,
    GET_ALL_ORDER: `${orderURL}/order/admin`,
    PAYMENT_DROPDOWN: `${orderURL}/order/paymentTypes`,
    CANCEL_ORDER: `${orderURL}/order/cancel`,
    UPDATE_DELIVERY: `${orderURL}/order/update/trackingDetails`,
    CREATE_ORDER: `${orderURL}/order`,
    USER_PRESCRIPTION: `${profileURL}/prescription/getAll`,
    ADD_PRESCRIPTION: `${profileURL}/prescription`,
    UPDATE_PRESCRIPTION: `${orderURL}/order/update/prescription`,
    GET_FEEDBACK_BY_ID: `${orderURL}/order/getOrderFeedback`,
    GET_ORDER_BY_STATUS: `${orderURL}/order/user/web`,
    GET_ORDER_STATISTICS: `${orderURL}/order/userOrderStatistics`,
    CUSTOM_MESSAGE: `${productURL}/product/customMessage`,
    PAYMENT_REFUND: `${orderURL}/payment/paynimo/refund`,
    UPDATE_ORDER_ADDRESS: `${orderURL}/order/updateAddress`,
    UPLOAD_PRESCRIPTION_API: `${orderURL}/order/add/prescription`,
    DELETE_PRESCRIPTION_API: `http://15.206.37.182:2318/api/v1/order/delete/prescription`,
    FILTER_COLUMN: `${orderURL}/order/admin`,
    REASSIGN_HUB: `${orderURL}/order/reassignHub`,
  },
  hub:{
    GET_ALL_HUB: `${orderURL}/hub/list`,
    CREATE_HUB: `${orderURL}/hub`,
    DELETE_HUB: `${orderURL}/hub`,
    GET_HUB_BY_ID:`${orderURL}/hub`,
    UPDATE_HUB: `${orderURL}/hub/update`,
  },
  pincode:{
    GET_ALL_PINCODE: `${orderURL}/pincode/GetAllPincode`,
    CREATE_PINCODE: `${orderURL}/pincode/addPincode`,
    DELETE_PINCODE: `${orderURL}/pincode/deletePincode`,
    GET_PINCODE_BY_ID: `${orderURL}/pincode/GetPincodeById`,
    UPDATE_PINCODE: `${orderURL}/pincode/updatePincode`,
    BULK_UPLOAD: `${orderURL}/pincode/upload-pincode-Excel`,
    ADD_ADDRESS: `${orderURL}/order/pincode`
  },
  molecule: {
    CREATE_MOLECULE: `${productURL}/molecule`,
    UPDATE_MOLECULE: `${productURL}/molecule`,
    GET_ALL_MOLECULE: `${productURL}/molecule`,
    DELETE_MOLECULE: `${productURL}/molecule`,
    GET_MOLECULE_BY_ID: `${productURL}/molecule`,
    SEARCH_MOLECULE: `${productURL}/molecule/getMoleculeName`,
    BULK_UPLOAD: `${productURL}/molecule/moleculeBulk`,
  },
  leads: {
    GET_ALL_LEAD: `${orderURL}/lead/getLead`,
    GET_STATISTIC_LEAD: `${orderURL}/lead/getLeadStatics`,
    GET_ALL_AGENT_LIST: `${authURL}/staff/getallAgent`,
    GET_ALL_LEAD_BY_AGENT: `${orderURL}/lead/getLeadByAgent`,
    GET_ACTION_STATUS: `${orderURL}/lead/getleadActionOptions`,
    UPDATE_LEAD: `${orderURL}/lead/updateLead`,
  },
  auth: {
    GET_USER_DETAIL: `${authURL}/user/getUserById`,
    GET_USER_BY_ID: `${authURL}/user/admin`,
    SIGNOUT: `${authURL}/user/logout`,
    UPDATE_USER: `${authURL}/user/updateAdmin`,
    CHANGE_PASSWORD: `${authURL}/user/changePasswordAdmin`,
    SIGN_IN: `${authURL}/user/signIn`,
    FORGOT_PASSWORD: `${authURL}/user/forgetPassword`,
    RESET_PASSWORD: `${authURL}/user/sendOtp`,
    VERIFY_OTP: `${authURL}/user/verifyOtpAdmin`,
    GET_ADDRESS: `${profileURL}/address/getPrimary`,
    UPDATE_ADDRESS: `${profileURL}/address/updatePrimary`,
    GET_ALL_ADDRESS: `${profileURL}/address/allAddressById`,
    GET_SINGLE_ADDRESS: `${profileURL}/address`,
    DELETE_ADDRESS: `${profileURL}/address`,
    UPDATE_USER_ADDRESS: `${profileURL}/address`,
    ADD_MRMED_CASH: `${medPlanURL}/cash/adminCash`,
    REMOVE_MRMED_CASH: `${medPlanURL}/cash/removeCash`,
    GET_MRMED_CASH: `${medPlanURL}/cash/cashHistory`,
  },
  banner: {
    ADD_BANNER: `${cmsURL}/cms/banner`,
    GET_BANNER_BY_ID: `${cmsURL}/cms/banner`,
    UPDATE_BANNER: `${cmsURL}/cms/banner`,
    DELETE_BANNER: `${cmsURL}/cms/banner`,
    GET_ALL_BANNER: `${cmsURL}/cms/banner?size=20`,
    BANNER_PRIORITY: `${cmsURL}/cms/banner/updatePriority`,
  },
  userData: {
    GET_ALL_USER_DATA: `${authURL}/user?size=10`,
    GET_USER_ORDERS: `${orderURL}/order/user`,
    CREATE_ADDRESS: `${profileURL}/address`,
    CHECK_PINCODE: `${orderURL}/pincode/pincodeServiceabilty`,
    CREATE_USER: `${authURL}/user/admin/create`,
    UPDATE_USER: `${authURL}/user/updateUserById`,
    CASH_HISTORY: `${medPlanURL}/cash/getCashHistoryById`,
    GET_USER_DATA: `${authURL}/user/getUserfromLeadId`,
    UPDATE_USER_DETAILS: `${authURL}/user/admin/updateUserDetails`,
    BULK_UPLOAD: `${medPlanURL}/cash/adminCash/excelBulk`,
    DELETE_USER: `${authURL}/user/admin/delete-user-account`
  },
  pap: {
    GET_ALL_PAP: `${medPlanURL}/pap?size=10`,
    GET_PAP_BY_ID: `${medPlanURL}/pap`,
    GET_ALL_CARER: `${medPlanURL}/carer?size=10`,
    GET_CARER_BY_ID: `${medPlanURL}/carer`,
    DELETE_PAP_BY_ID: `${medPlanURL}/PAP`,
  },
  notification: {
    GET_ALL_NOTIFICATION: `${notificationURL}/notification?size=20`,
    UPDATE_NOTIFICATION: `${notificationURL}/notification`,
  },
  coupon: {
    ADD_COUPON: `${medPlanURL}/coupon`,
    UPDATE_COUPON: `${medPlanURL}/coupon`,
    DELTE_COUPON: `${medPlanURL}/coupon`,
    GET_ALL_COUPON: `${medPlanURL}/coupon?size=10`,
    GET_COUPON_BY_ID: `${medPlanURL}/coupon`,
    GET_COUPON_BY_Code: `${medPlanURL}/coupon/getCode`,
    COUPON_DROPDOWN: `${medPlanURL}/coupon/group`,
    GET_ALL_COUPONLOG: `${medPlanURL}/coupon/couponLog?size=10`,
  },
  blog: {
    ADD_BLOG: `${adminURL}/blog`,
    UPDATE_BLOG: `${adminURL}/blog`,
    DELTE_BLOG: `${adminURL}/blog`,
    GET_ALL_BLOG: `${adminURL}/blog?size=20`,
    GET_BLOG_BY_ID: `${adminURL}/blog`,
    BLOG_DROPDOWN: `${adminURL}/blog/group`,
    BLOG_CATEGORY: `${adminURL}/blog/category/list`,
    BLOG_PRIORITY: `${adminURL}/blog/updatePriority`,
    ADD_CATEGORY: `${adminURL}/blog/category`,
    UPDATE_CATEGORY: `${adminURL}/blog/category`,
    DELETE_CATEGORY: `${adminURL}/blog/category`,
    GET_ALL_CATEGORY: `${adminURL}/blog/category?size=10`,
    GET_CATEGORY_BY_ID: `${adminURL}/blog/category`,
  },
  importOrder: {
    UPDATE_IMPORT: `${medPlanURL}/import`,
    GET_ALL_IMPORT: `${medPlanURL}/import?size=10`,
    GET_IMPORT_BY_ID: `${medPlanURL}/import`,
  },
  chronic: {
    ADD_CHRONIC: `${productURL}/product/condition`,
    UPDATE_CHRONIC: `${productURL}/product/condition`,
    DELTE_CHRONIC: `${productURL}/product/condition`,
    GET_ALL_CHRONIC: `${productURL}/product/condition?size=20`,
    GET_CHRONIC_BY_ID: `${productURL}/product/condition`,
    CHRONIC_PRIORITY: `${productURL}/product/condition/updatePriority`,
  },
  category: {
    ADD_CATEGORY: `${productURL}/homeCategory/create`,
    UPDATE_CATEGORY: `${productURL}/homeCategory/update`,
    DELTE_CATEGORY: `${productURL}/homeCategory/delete`,
    GET_ALL_CATEGORY: `${productURL}/homeCategory/getAllHomeCategory`,
    GET_CATEGORY_BY_ID: `${productURL}/homeCategory/homeCategoryById`,
    CATEGORY_PRIORITY: `${productURL}/homeCategory/homeCategory/updatePriority`,
  },
  supplier: {
    ADD_SUPPLIER: `${productURL}/supplier`,
    UPDATE_SUPPLIER: `${productURL}/supplier`,
    DELTE_SUPPLIER: `${productURL}/supplier`,
    GET_ALL_SUPPLIER: `${productURL}/supplier?size=10`,
    GET_SUPPLIER_BY_ID: `${productURL}/supplier`,
  },
  common: {
    SINGLE_IMAGE_UPLOAD: `${productURL}/upload/uploadFile/single`,
    MULTIPLE_IMAGE_UPLOAD: `${productURL}/upload/uploadFile`,
    CREATE_PRESCRIPTION: `${profileURL}/prescription`,
    GET_STOCK_NOTIFICATION: `${productURL}/notify/stock`,
    GET_SUBSCRIBER: `${cmsURL}/cms/newsletter?size=10`,
    GLOBAL_VARIABLES: `${medPlanURL}/global`,
    UPDATE_GLOBAL_VARIABLES: `${medPlanURL}/global`,
    DELIVERY_CHARGE: `${medPlanURL}/global/delivery`,
    UPDATE_DELIVERY_CHARGE: `${medPlanURL}/global/delivery`,
    DELETE_DELIVERY_CHARGE: `${medPlanURL}/global/delivery`,
    ADD_DELIVERY_CHARGE: `${medPlanURL}/global/delivery`,
    GET_TERMS: `${cmsURL}/cms/policy`,
    UPDATE_TERMS: `${cmsURL}/cms/policy`,
    GET_TERMS_BY_ID: `${cmsURL}/cms/policy`,
    GET_ALL_STATES: `${orderURL}/order/states`,
    VALIDATE_TOKEN: `${authURL}/auth/validateToken`,
    GET_ALL_FEEDBACK: `${orderURL}/order/getOrderFeedback`,
  },
  offer: {
    ADD_OFFER: `${medPlanURL}/offer`,
    UPDATE_OFFER: `${medPlanURL}/offer`,
    GET_ALL_OFFER: `${medPlanURL}/offer`,
    GET_ALL_OFFER_BY_ID: `${medPlanURL}/offer/getById`,
    DELETE_OFFER_BY_ID: `${medPlanURL}/offer/removeById`,
    GET_OFFER_BY_CATEGORY: `${medPlanURL}/offer/offerListByCategory`,
  },
  deliveryFollowUp: {
    GET_ALL_DELIVERY_FOLLOW_UP: `${orderURL}/deliveryfollowup?size=10`,
    DELETE_DELIVERY_FOLLOW_UP_BY_ID: `${orderURL}/deliveryfollowup`,
  },
  leadLog: {
    CREATE_LEAD_LOG: `${orderURL}/leadLog`,
    GET_ALL_LEAD_LOG: `${orderURL}/leadLog?size=10`,
  },
  karkinos:{
    GET_ALL_KARKINOS_LIST: `${medPlanURL}/karkinos/get-users-list`
  }
};

export const secretKey = "PXgNtTli3A_px_hvd";
